// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import {
  ACCEPT_EVENTS,
  COPY_EVENTS,
  EVENT,
  EVENT_CONFLICTS,
  TEACHER_CONFIRM_EVENTS,
  TEACHER_EVENT,
} from "./CONSTANTS";
import history from "navigation";
import { LOGIN } from "navigation/CONSTANTS";
import { logout } from "services";
import qs from "qs";

const out = () => {
  localStorage.removeItem("token");
  logout()
    .then((res) => {})
    .catch((err) => {});
  history.push(LOGIN, { from: { pathname: history.location.pathname } });
};

/**
 * Function to fetch all the users.
 */
export const getEvents = (par) => {
  try {
    const params = { ...par, page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(EVENT(), { params }).catch((err) => {
        if (err.response.status === 401) {
          out();
        }
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

/**
 * Send POST request to create new event
 * @param {Object} data
 * @returns Promise
 */

export const createEvent = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(EVENT(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in createEvent service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send DELETE request to delete event
 * @param Number id
 * @returns Promise
 */

export const deleteEvent = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(EVENT(id))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in deleteEvent service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to edit given event
 * @param {Number} id
 * @param {Object} data
 */
export const editEvent = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(EVENT(id), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in editEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const copyEvent = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(COPY_EVENTS(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in copyEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const acceptEvents = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ACCEPT_EVENTS(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in acceptEvents service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Event = {
  get: getEvents,
  post: createEvent,
  put: editEvent,
  delete: deleteEvent,
  copyEvent: copyEvent,
  acceptEvents: acceptEvents,
};

/**
 * Send POST request to create event by teacher
 * @param Obejct data
 * @returns Promise
 */

export const teacherCreateEvent = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(TEACHER_EVENT(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in teacherCreateEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send DELETE request to delete event
 * @param Number id
 * @returns Promise
 */

export const teacherDeleteEvent = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(TEACHER_EVENT(id))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in teacherDeleteEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to edit given event
 * @param {Number} id
 * @param {Object} data
 */
export const teacherEditEvent = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(TEACHER_EVENT(id), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in editEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const teacherAcceptEvents = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(TEACHER_CONFIRM_EVENTS(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in acceptEvents service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const TeacherEvent = {
  post: teacherCreateEvent,
  delete: teacherDeleteEvent,
  put: teacherEditEvent,
  acceptEvents: teacherAcceptEvents,
};

export const getEventConflicts = (params) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(EVENT_CONFLICTS(), {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in createEvent service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
