/**
 * theme 

 */

export const theme = {
  palette: {
    primary: {
      lightest: "#D6F1FF",
      light: "#85D6FF",
      base: "#008FD5",
      dark: "#00618E",
      darkest: "#003346",
    },
    secondary: {
      lightest: "#C55EFE",
      light: "#AE1CFF",
      base: "#8C00D9",
      dark: "#6800A2",
      darkest: "#530080",
    },
    ternary: {
      light: "#F7A1B2",
      base: "#EE2B53",
      dark: "#AA0E2D",
    },
    alert: {
      light: "#FEF2E7",
      base: "#F68013",
      dark: "#D2590F",
    },
    background: {
      lightest: "#FFFFFF",
      light: "#F4F5F6",
      base: "#E8EBED",
      dark: "#82939B",
      darkest: "#49545B",
    },
    error: {
      light: "#FBEBEB",
      base: "#CC0000",
      dark: "#880000",
    },
    success: {
      light: "#ECF7EC",
      base: "#00AA00",
      dark: "#006600",
    },
    notify: {
      light: "#FFF3C0",
      base: "#FEDC2A",
      dark: "#B19A1D",
    },
    text: {
      lightest: "#FFFFFF",
      lighter: "#A7B7C7",
      light: "#4F6F8F",
      base: "#001833",
      dark: "#001023",
    },
    gradient: {
      base: "linear-gradient(180deg, rgba(140, 0, 217, 0.4) 0%, rgba(0, 143, 213, 0.4) 100%), #00618E;",
    },
  },
};
