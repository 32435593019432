import React, { useState } from "react";
import { AG_GRID_LOCALE_PL } from "i18n/ag-grid/locale.pl";

import { Employee, Attendee, Subject, Room } from "services";
import { useSelector } from "react-redux";
import { Button, Select, Text } from "pcln-design-system";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CONTRACT } from "navigation/CONSTANTS";
import { Can } from "components";
import { useRBAC } from "utils/useRBAC";
import { useAuth } from "navigation/Auth/ProvideAuth";

const GridCheckbox = (props) => {
  const checkedHandler = (event) => {
    props.setValue(event.target.checked);
  };
  return (
    <input
      style={{
        height: "15px",
        width: "15px",
        marginTop: "4px",
      }}
      type={"checkbox"}
      id={`checkbox-${props.rowIndex}`}
      checked={props.value}
      onChange={checkedHandler}
    />
  );
};

const GridSelect = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "edit_data" });
  const constants = useSelector(
    (state) => state.constants[props.valuesToSelect]
  );
  const checkedHandler = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Select
        py={0}
        type={"checkbox"}
        id={`checkbox-${props.rowIndex}`}
        value={props.value}
        onChange={checkedHandler}
      >
        {constants?.map((typ) => (
          <option value={typ} key={typ}>
            {t(`${props.selectFrom}.${typ}`)}
          </option>
        ))}
      </Select>
    </div>
  );
};

const GridLink = (cell) => {
  const history = useHistory();
  return (
    <>
      {cell.data[`employeeId`] ? (
        <Can
          perform={"tab:EDIT_DATA/CONTRACTS"}
          yes={() => (
            <Button
              variation={"link"}
              onClick={() => {
                history.push(`${CONTRACT}?v=${cell.data[`employeeId`]}`);
              }}
            >
              Edycja kontraktów
            </Button>
          )}
        />
      ) : null}
      <Button
        ml={3}
        variation={"link"}
        onClick={() => {
          if (cell.data[`${cell.selectedType}Id`])
            cell.context.onCellDelete(cell.data);
          else cell.context.onNewCellDelete(cell.data);
        }}
      >
        Usuń
      </Button>
    </>
  );
};

const GridSelectSubject = (props) => {
  const checkedHandler = (event) => {
    props.setValue(event.target.value === "" ? null : event.target.value);
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Select
        py={0}
        type={"parentSubject"}
        id={`parentSubject-${props.rowIndex}`}
        value={props.value || ""}
        onChange={checkedHandler}
      >
        <option value={null} />
        {props.rowData
          ?.filter(
            (i) => i.subjectId?.toString() !== props.data.subjectId?.toString()
          )
          .map((typ) => (
            <option value={typ.subjectId} key={typ.subjectId}>
              {typ.name}
            </option>
          ))}
      </Select>
    </div>
  );
};

const GridUserCell = (props) => {
  if (props.value) return <Text>{props.value}</Text>;
  else
    return (
      <Button
        ml={3}
        variation={"link"}
        onClick={() => {
          props.context.assignUserToEmployee(props.data);
        }}
      >
        Przypisz
      </Button>
    );
};

const useEditData = ({
  onCellDelete = () => {},
  onNewCellDelete = () => {},
  assignUserToEmployee = () => {},
  rowData,
}) => {
  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const [editedRows, setEditedRows] = useState([]);

  const cellValueChangedHandler = (event) => {
    if (editedRows.findIndex((row) => row.rowIndex === event.rowIndex) === -1) {
      setEditedRows([
        ...editedRows,
        { rowIndex: event.rowIndex, data: event.data },
      ]);
    }
  };
  const { user } = useAuth();
  const { check } = useRBAC();

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const data_types = {
    employee: {
      value: "employee",
      text: "Nauczyciel",
      func: Employee,
    },
    subject: {
      value: "subject",
      text: "Przedmiot",
      func: Subject,
    },
    location: {
      value: "location",
      text: "Sala / Miejsce",
      func: Room,
    },
    attendee: {
      value: "attendee",
      text: "Grupa",
      func: Attendee,
    },
  };

  const gridConfig = {
    rowData: rowData,
    onGridReady: onGridReady,
    frameworkComponents: {
      BooleanCellRenderer: GridCheckbox,
      SelectCellRenderer: GridSelect,
      ButtonsCellRenderer: GridLink,
      SearchCellRenderer: GridSelectSubject,
      UserCellRenderer: GridUserCell,
    },
    context: { onCellDelete, onNewCellDelete, assignUserToEmployee },

    localeText: AG_GRID_LOCALE_PL,
    stopEditingWhenCellsLoseFocus: true,
    onCellValueChanged: cellValueChangedHandler,
    suppressDragLeaveHidesColumns: true,
  };
  const columnDefinitions = (selectedType) => {
    const columns = (cols = []) => [
      {
        field: `${selectedType}Id`,
        headerName: "Id",
        sortable: false,
        filter: true,
        resizable: false,
        flex: 0.2,
        checkboxSelection: false,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      {
        field: "name",
        headerName: "Nazwa",
        sortable: true,
        filter: true,
        resizable: false,
        flex: 0.8,
        checkboxSelection: false,
        editable: true,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      {
        field: "isActive",
        headerName: "Aktywny",
        sortable: true,
        filter: true,
        resizable: false,
        flex: 0.2,
        checkboxSelection: false,
        editable: false,
        cellRenderer: "BooleanCellRenderer",

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      ...cols,
      {
        headerName: "Akcje",
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0.5,
        checkboxSelection: false,
        editable: false,
        cellRenderer: "ButtonsCellRenderer",
        cellRendererParams: { selectedType: selectedType },
      },
    ];

    switch (selectedType) {
      case "employee":
        return columns([
          {
            field: "contractType",
            headerName: "Typ kontraktu",
            sortable: false,
            filter: true,
            resizable: false,
            flex: 0.6,
            checkboxSelection: false,
            editable: false,
            cellRenderer: "SelectCellRenderer",
            cellRendererParams: {
              selectFrom: "contract",
              valuesToSelect: "contractType",
            },

            filterParams: {
              suppressAndOrCondition: true,
              closeOnApply: true,
              buttons: ["apply", "clear", "reset", "cancel"],
            },
          },
          {
            field: "contractHours",
            headerName: "Godziny etatowe",
            sortable: false,
            filter: false,
            resizable: false,
            flex: 0.35,
            checkboxSelection: false,
            editable: true,

            filterParams: {
              suppressAndOrCondition: true,
              closeOnApply: true,
              buttons: ["apply", "clear", "reset", "cancel"],
            },
          },

          ...(check(user().scope, "user:add")
            ? [
                {
                  field: "userName",
                  headerName: "Login",
                  sortable: false,
                  filter: false,
                  resizable: false,
                  flex: 0.35,
                  checkboxSelection: false,
                  editable: false,
                  cellRenderer: "UserCellRenderer",

                  filterParams: {
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    buttons: ["apply", "clear", "reset", "cancel"],
                  },
                },
              ]
            : []),
        ]);
      case "subject":
        return columns([
          {
            valueGetter: (params) => {
              return params.data.parentSubjectId;
            },
            valueSetter: (params) => {
              params.data.parentSubjectId = params.newValue;
              return true;
            },
            headerName: "Przedmiot nadrzędny",
            sortable: false,
            filter: false,
            resizable: false,
            flex: 0.7,
            checkboxSelection: false,
            editable: false,
            cellRenderer: "SearchCellRenderer",
            cellRendererParams: { rowData },
          },
          {
            field: "type",
            headerName: "Typ zajęć",
            sortable: false,
            filter: true,
            resizable: false,
            flex: 0.7,
            checkboxSelection: false,
            editable: false,
            cellRenderer: "SelectCellRenderer",
            cellRendererParams: {
              selectFrom: "eventType",
              valuesToSelect: "eventType",
            },

            filterParams: {
              suppressAndOrCondition: true,
              closeOnApply: true,
              buttons: ["apply", "clear", "reset", "cancel"],
            },
          },
        ]);
      case "location":
        return columns();
      case "attendee":
        return columns();

      default:
        return columns();
    }
  };

  return {
    data_types,
    gridConfig,
    columnDefinitions,
    editedRows: { get: editedRows, set: setEditedRows },
  };
};

export { useEditData };
