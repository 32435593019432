import React from "react";
import { EditData } from "./EditData";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "navigation/Auth/PrivateRoute";
import { CONTRACT } from "navigation/CONSTANTS";
import EditContract from "./components/EditContract";

const Index = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} perform={"tab:EDIT_DATA"}>
        <EditData />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${CONTRACT}`}
        perform={"tab:EDIT_DATA/CONTRACTS"}
      >
        <EditContract />
      </PrivateRoute>
    </Switch>
  );
};

export default Index;
