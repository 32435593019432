import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Card,
  Flex,
  Input,
  Text,
  Box,
  Label,
} from "pcln-design-system";
import Select from "react-select";
import { User } from "services";
import { PulseLoader } from "react-spinners";
import { useIsMounted } from "utils";

const NewUserPopup = ({ close }) => {
  const isMounted = useIsMounted();
  const [available_scopes, setAvailable_scopes] = useState([]);
  const [parentUsers, setParentUsers] = useState([]);
  const [selectedScopes, setSelectedScopes] = useState([]);
  const [selectedParent, setSelectedParents] = useState([]);

  const name = useRef(null);
  const email = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    User.get()
      .then((res) => {
        if (isMounted)
          setParentUsers(
            res.data.data.results
              .map((i) => ({
                value: i.userId,
                label: i.userName,
                ...i,
              }))
              .slice(0, 3)
          );
      })
      .catch((err) => {});

    User.getScopes()
      .then((res) => {
        if (isMounted)
          setAvailable_scopes(
            res.data.data.map((i) => ({ value: i, label: i }))
          );
      })
      .catch((err) => {});

    return () => {};
  }, [isMounted]);

  const handleScopeSelectChange = (options) => {
    setSelectedScopes(options);
  };
  const handleParentUsersSelectChange = (options) => {
    setSelectedParents(options);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      const data = {
        name: name.current.value,
        emailAddress: email.current.value,
        scopes: selectedScopes.map((i) => i.value),
        ...(selectedParent.userId && { parentUserId: selectedParent.userId }),
      };

      setLoading(true);
      User.post(data)
        .then((res) => {
          if (isMounted) setLoading(false);
          close();
        })
        .catch((err) => {
          if (isMounted) setLoading(false);
        });
    }
  };

  const [error, setError] = useState(null);
  const validate = useCallback(() => {
    if (name.current.value === "") {
      setError("Nazwa użytkownika jest wymagana");
      return false;
    }
    if (email.current.value === "") {
      setError("Email użytkownika jest wymagany");
      return false;
    }

    setError(null);
    return true;
  }, [name, email]);

  const validateParent = useCallback(() => {
    if (!selectedParent?.userId) {
      setError("Szablon użytkownika jest wymagany");
      return false;
    } else {
      setError(null);
      return true;
    }
  }, [selectedParent?.userId]);

  useEffect(() => {
    validateParent();
    return () => {};
  }, [selectedParent?.userId, validateParent]);

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <form onSubmit={onSubmit}>
        <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Text mt={2} mb={1} bold fontSize={3}>
              Dane nowego użytkownika
            </Text>
          </Flex>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="name">
                Nazwa użytkownika:
              </Label>
              <Input id="name" name="name" ref={name} />
            </Box>
          </Flex>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="email">
                Adres email:
              </Label>
              <Input id="email" name="email" ref={email} />
            </Box>
          </Flex>

          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="parentUser">
                Użyj szablonu użytkownika:
              </Label>
              <Select
                id="parentUser"
                options={parentUsers}
                isClearable
                onChange={handleParentUsersSelectChange}
                placeholder={"Wybierz użytkownika..."}
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue === "") {
                    return (
                      <Text
                        fontSize={1}
                      >{`Wystąpił problem z pobieraniem wartości z serwera`}</Text>
                    );
                  } else
                    return (
                      <Text
                        fontSize={1}
                      >{`Nie znaleziono: "${inputValue}"`}</Text>
                    );
                }}
              />
            </Box>
          </Flex>

          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="scopes">
                Dodatkowe uprawnienia:
              </Label>
              <Select
                id="scopes"
                isMulti
                options={available_scopes}
                onChange={handleScopeSelectChange}
                placeholder={"Wybierz uprawnienia..."}
                noOptionsMessage={({ inputValue }) => {
                  if (inputValue === "") {
                    return (
                      <Text
                        fontSize={1}
                      >{`Wystąpił problem z pobieraniem wartości z serwera`}</Text>
                    );
                  } else
                    return (
                      <Text
                        fontSize={1}
                      >{`Nie znaleziono: "${inputValue}"`}</Text>
                    );
                }}
              />
            </Box>
          </Flex>
          {error ? (
            <Flex
              width={1}
              flexDirection={"row"}
              p={2}
              justifyContent={"space-around"}
              alignItems="center"
            >
              <Text bold color={"error.base"}>
                {error}
              </Text>
            </Flex>
          ) : null}

          <Flex
            width={1}
            flexDirection={"row"}
            justifyContent="space-around"
            mt={2}
            mb={1}
          >
            <Button
              color={"primary"}
              variation="outline"
              onClick={close}
              type="button"
            >
              Anuluj
            </Button>
            <Button color={"secondary"} type="submit">
              <Flex flexDirection="row">
                {loading ? (
                  <PulseLoader size={10} color={"white"} />
                ) : (
                  <Text ml={2}>Dodaj</Text>
                )}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};

export default NewUserPopup;
