import {
  PLAN,
  REPORTS,
  EXCEPTIONS,
  EDIT_DATA,
  USERS,
} from "navigation/CONSTANTS";

const tabConfig = [
  {
    text: "Plan zajęć",
    icon: "Tasks",
    path: PLAN,
    permission: "tab:PLAN",
    //
  },
  {
    text: "Wyjątki",
    icon: "Shuffle",
    path: EXCEPTIONS,
    permission: "tab:EXCEPTIONS",
  },
  {
    text: "Raporty",
    icon: "Layout",
    path: REPORTS,
    permission: "tab:REPORTS",
    //
  },
  {
    text: "Edycja danych",
    icon: "Pencil",
    path: EDIT_DATA,
    permission: "tab:EDIT_DATA",
    //
  },
  {
    text: "Użytkownicy",
    icon: "User_1",
    path: USERS,
    permission: "tab:USERS",
    //
  },
];

export default tabConfig;
