import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Card, Flex, Box, Button, Text } from "pcln-design-system";
import FullCalendar from "@fullcalendar/react";
import { PulseLoader } from "react-spinners";

import { usePlan } from "./usePlan";
import {
  GroupedButton,
  ThemedButton,
  DatePickerButton,
  Icon,
  Can,
} from "components";
import "./Plan.css";
import Popup from "reactjs-popup";

import EditLesson from "./components/EditLesson";
import ControlledSearchBar from "./components/SearchBar";
import { Event, TeacherEvent } from "services";
import { useIsMounted, useQueryParams } from "utils";
import { useHistory } from "react-router";
import moment from "moment";
import { generateEmptyEvents } from "./events";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LastWeekImport from "./components/LastWeekImport";
import { LOGIN, PLAN } from "navigation/CONSTANTS";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { setConstants } from "redux/actions/constantsActions";

import { colors } from "styles/events";
import Legend from "components/Legend";
import useLocalStorage from "utils/useLocalStorage";

const validateQueryDate = (query) => {
  // 1. get current date
  const today = moment();

  if (query === null) return today.valueOf();

  // 2.get start of last year
  const startOfLastYear = moment(today).startOf("year").subtract(1, "year");
  // 3. get end of next year
  const endOfNextYear = moment(today).endOf("year").add(1, "year");
  // 4. return min max from this range
  let actual = moment.max(startOfLastYear, moment(Number(query)));
  actual = moment.min(endOfNextYear, actual);

  return actual.valueOf();
};

const Plan = () => {
  /**
   * Top-level hooks
   */
  // const dispatch = useDispatch();
  const auth = useAuth();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const history = useHistory();
  let query = useQueryParams();

  // const a = check(auth.user().scope, "event:add");

  const {
    teachers: tt,
    rooms: r,
    groups: g,
  } = useSelector((state) => state.constants);

  const teachers = tt.map((i) => ({
    ...i,
    name: `${i.name}${i.isActive === false ? " - nieaktywny" : ""}`,
  }));
  const rooms = r.map((i) => ({
    ...i,
    name: `${i.name}${i.isActive === false ? " - nieaktywny" : ""}`,
  }));

  const groups = g.map((i) => ({
    ...i,
    name: `${i.name}${i.isActive === false ? " - nieaktywny" : ""}`,
  }));

  const [unsavedChanges] = useState(true);

  const [loading, setLoading] = useState(false);
  const [downloadedEvents, setDownloadedEvents] = useState([]);
  const [refresh, setRefresh] = useState(false);

  /**
   * Hook for fullcalendar
   */
  const { config, title, changeDay, selectedDate } = usePlan({
    initialDate: validateQueryDate(query.get("date")),
    editEvent: (eventData) => {
      if (isTeacher) {
        eventData.setExtendedProp("data", {
          teacher: { name: user.name, employeeId: user.employeeId },
          ...eventData.extendedProps.data,
        });
      }
      setOpenEvent(eventData);
      setOpenModal(true);
    },
    fetchedEvents: downloadedEvents,
  });

  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(setConstants());
      return () => {};
    },
    // eslint-disable-next-line
    []
  );

  /**
   * validation of query params
   *
   * */
  useEffect(() => {
    if (query.has("type") && query.has("v")) {
      if (query.get("v") === "null" || query.get("v") === "undefined") {
        history.push(PLAN);
      }
      //show filtered for today
    } else if (query.has("date")) {
      //validate date
    }
    if (query.has("type") && !query.has("v")) {
      history.push(PLAN);
    }
    // else {
    //   //invalid query - default view
    // }
    return () => {};
    // eslint-disable-next-line
  }, [query]);

  const [filterData, setFilterData] = useState({
    type: query.get("type"),
    value: { id: query.get("v") },
    date: selectedDate,
  });

  useEffect(() => {
    setFilterData({ ...filterData, date: selectedDate });
    return () => {};
    // eslint-disable-next-line
  }, [selectedDate]);

  // Modal for editing event
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  //currently edited event
  const [openEvent, setOpenEvent] = useState(null);

  //Modal for importing whole week
  const [openImportLastWeek, setOpenImportLastWeek] = useState(false);
  const closeImportLastWeek = () => setOpenImportLastWeek(false);

  const [showEmptyHours, setShowEmptyHours] = useLocalStorage(
    "showEmptyHours",
    false
  );

  useEffect(
    () => {
      if (
        (filterData.type !== null && filterData.value.id !== null) ||
        auth.user().scope.includes("qpay:teacher")
      ) {
        setLoading(true);

        const params = {
          startTime: moment(filterData.date).format('YYYY-MM-DDTHH:mm:ss'),
          endTime: moment(filterData.date).add(5, "days").format('YYYY-MM-DDTHH:mm:ss'),
          ...(filterData.type === "teacher" && {
            employeeId: filterData.value.id,
          }),
          ...(filterData.type === "group" && {
            attendeeId: filterData.value.id,
          }),
          ...(filterData.type === "room" && {
            locationId: filterData.value.id,
          }),
        };
        Event.get({ ...params, pageSize: 120 })
          .then((res) => {
            if (isMounted()) {
              const allEvents = generateEmptyEvents(filterData);
              const resEvents = res.data.data.results.map((i, ind) => ({
                backgroundColor: colors[i.eventColor],
                id: i.eventId,
                extendedProps: {
                  data: {
                    eventId: i.eventId,
                    // subject: i.subject?.subjectId,
                    subject: i.subject,
                    room: i.location,
                    group: i.attendees, //.map((a) => a.attendeeId),
                    teacher: i.teacher,
                    absence: i.absence,
                    absenceReason: i.reasonForAbsence,
                    absenceEffect: i.absenceOutcome,
                    paymentForm: i.paymentForm,
                    substituteTeacher: i.substituteTeacher,
                    recurrent: i.recurrent,
                  },
                },
                start: i.startTime,
                end: i.endTime,
              }));

              if (showEmptyHours) {
                setDownloadedEvents(allEvents.concat(resEvents));
              } else {
                setDownloadedEvents(resEvents);
              }
              setLoading(false);
            }
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              auth.signout().then((res) => {
                history.push(LOGIN);
              });
            }
            console.log("axios err=", err);
            if (isMounted()) setLoading(false);
          });
      }
      return () => {};
    },
    // eslint-disable-next-line
    [
      filterData.type,
      filterData.value,
      filterData.date,
      refresh,
      showEmptyHours,
      isMounted,
    ]
  );

  const [teacherData, setTeacherData] = useState(
    query.get("type") === "teacher" ? { id: query.get("v") } : null
  );
  const [groupData, setGroupData] = useState(
    query.get("type") === "group" ? { id: query.get("v") } : null
  );
  const [roomData, setRoomData] = useState(
    query.get("type") === "room" ? { id: query.get("v") } : null
  );

  useEffect(() => {
    switch (filterData.type) {
      case "teacher":
        setGroupData(null);
        setRoomData(null);
        break;
      case "group":
        setTeacherData(null);
        setRoomData(null);
        break;
      case "room":
        setTeacherData(null);
        setGroupData(null);
        break;
      default:
        break;
    }
    return () => {};
  }, [filterData]);

  useEffect(() => {
    if (filterData.type !== null && filterData.value.id !== null)
      history.replace(
        `${PLAN}?type=${filterData.type}&v=${filterData.value.id}&date=${selectedDate}`
      );
    return () => {};
  }, [selectedDate, filterData.type, filterData.value.id, history]);

  const searchEvents = (type, data) => {
    setFilterData({ ...filterData, type: type, value: data });
  };

  const saveEventHandler = () => {
    closeModal();
    closeImportLastWeek();
    setRefresh(!refresh);
  };

  const filtersEmpty = filterData.type === null || filterData.value.id === null;
  const user = auth.user();
  const isTeacher = user.scope.includes("qpay:teacher");

  const [loadingAccept, setLoadingAccept] = useState(false);
  const handleAcceptWeek = () => {
    const data = {
      startDate: moment(filterData.date).startOf("isoweek").format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(filterData.date).endOf("isoweek").format('YYYY-MM-DDTHH:mm:ss'),
      ...(!isTeacher && { employeeId: filterData.value.id }),
    };
    const Instance = isTeacher ? TeacherEvent : Event;

    setLoadingAccept(true);
    Instance.acceptEvents(data)
      .then((res) => {
        setLoadingAccept(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setLoadingAccept(false);
        alert(err);
        console.log(err);
      });
  };

  return (
    <>
      <Flex
        width={1}
        flexDirection="row"
        mb={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Legend />
        {unsavedChanges && (
          <Can
            perform={"event:acceptWeek"}
            yes={() => {
              const disabled =
                !isTeacher &&
                (filterData.type !== "teacher" || filterData.value.id === null);
              return (
                <Button
                  color={"secondary"}
                  onClick={handleAcceptWeek}
                  disabled={disabled}
                  ml="auto"
                >
                  <Flex flexDirection="row">
                    {loadingAccept ? (
                      <PulseLoader size={10} color={"white"} />
                    ) : (
                      <>
                        <Icon
                          name="Diskette"
                          width="20px"
                          height="20px"
                          color={disabled ? "text.light" : "primary.lightest"}
                        />
                        <Text ml={2}>Zatwierdź tydzień</Text>
                      </>
                    )}
                  </Flex>
                </Button>
              );
            }}
          />
        )}

        <Can
          perform={"event:importFromLastWeek"}
          yes={() => (
            <Button
              ml={2}
              variation={"outline"}
              disabled={!filterData.value.id}
              onClick={() => {
                setOpenImportLastWeek(true);
              }}
            >
              <Flex flexDirection="row">
                <Icon
                  name="Upload"
                  width="20px"
                  height="20px"
                  color={"primary.base"}
                />
                <Text ml={2}>Import z poprzedniego tygodnia</Text>
              </Flex>
            </Button>
          )}
        />
      </Flex>

      <SCard
        color="background.lightest"
        boxShadowSize="md"
        borderWidth={0}
        borderRadius={5}
      >
        <Flex
          width={1}
          height={"100%"}
          flexDirection="column"
          className={"container"}
        >
          <Flex
            width={1}
            height={"auto"}
            py={3}
            px={3}
            justifyContent="space-between"
            alignItems="center"
            flexWrap={"wrap"}
          >
            <Can
              perform={"plan:searchByTeacher"}
              giveBoolean={true}
              ret={(valid) => (
                <Box mr={2} title={valid ? null : "Brak uprawnień"}>
                  <ControlledSearchBar
                    isDisabled={!valid}
                    width={"300px"}
                    value={
                      filterData.type === "teacher"
                        ? teachers.filter(
                            ({ id }) =>
                              id.toString() === teacherData?.id?.toString()
                          )
                        : isTeacher
                        ? { name: user.name, id: user.employeeId }
                        : null
                    }
                    data={teachers}
                    placeholder={"Nauczyciel..."}
                    onSelection={(data) => {
                      setTeacherData(data);
                      searchEvents("teacher", data);
                    }}
                  />
                </Box>
              )}
            />
            <Can
              perform={"plan:searchByGroup"}
              giveBoolean={true}
              ret={(valid) => (
                <Box mr={2} title={valid ? null : "Brak uprawnień"}>
                  <ControlledSearchBar
                    isDisabled={!valid}
                    width={"180px"}
                    value={
                      filterData.type === "group"
                        ? groups.filter(
                            ({ id }) =>
                              id.toString() === groupData?.id?.toString()
                          )
                        : null
                    }
                    ml={"auto"}
                    data={groups}
                    placeholder={"Grupa..."}
                    onSelection={(data) => {
                      setGroupData(data);
                      searchEvents("group", data);
                    }}
                  />
                </Box>
              )}
            />
            <Can
              perform={"plan:searchByGroup"}
              giveBoolean={true}
              ret={(valid) => (
                <Box mr={2} title={valid ? null : "Brak uprawnień"}>
                  <ControlledSearchBar
                    isDisabled={!valid}
                    width={"180px"}
                    value={
                      filterData.type === "room"
                        ? rooms.filter(
                            ({ id }) =>
                              id.toString() === roomData?.id?.toString()
                          )
                        : null
                    }
                    ml={"auto"}
                    data={rooms}
                    placeholder={"Sala..."}
                    onSelection={(data) => {
                      setRoomData(data);
                      searchEvents("room", data);
                    }}
                  />
                </Box>
              )}
            />
            <Box mr={2}>
              <ThemedButton
                variation={"plain"}
                onClick={() => setShowEmptyHours(!showEmptyHours)}
                disabled={filtersEmpty && !isTeacher}
              >
                {`${!showEmptyHours ? "Pokaż" : "Ukryj"} puste godziny`}
              </ThemedButton>
            </Box>
            <Box mr={2}>
              <ThemedButton
                variation={"plain"}
                onClick={() => changeDay(0)}
                disabled={filtersEmpty && !isTeacher}
              >
                Dzisiaj
              </ThemedButton>
            </Box>
            <Box mr={2}>
              <GroupedButton
                width={500}
                my={"auto"}
                p={1}
                onLeft={() => changeDay(-1)}
                onRight={() => changeDay(1)}
                disabled={filtersEmpty && !isTeacher}
              >
                {title}
              </GroupedButton>
            </Box>
            <Box mr={2}>
              <DatePickerButton
                disabled={filtersEmpty && !isTeacher}
                onSelect={(date) => {
                  changeDay(date);
                }}
              />
            </Box>
          </Flex>
          {filtersEmpty && !isTeacher ? (
            <Flex
              width={1}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text color={"text.lighter"}>{t("plan.select_filters")}</Text>
            </Flex>
          ) : loading ? (
            <Flex
              width={1}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PulseLoader color={"blue"} margin={10} />
            </Flex>
          ) : (
            <FullCalendar height="auto" {...config} />
          )}
        </Flex>
      </SCard>
      <StyledPopup open={openModal} closeOnDocumentClick onClose={closeModal}>
        {(close) => (
          <EditLesson
            data={openEvent}
            close={close}
            onSave={saveEventHandler}
            filterData={filterData}
          />
        )}
      </StyledPopup>
      <StyledPopup
        open={openImportLastWeek}
        closeOnDocumentClick
        onClose={closeImportLastWeek}
      >
        {(close) => (
          <LastWeekImport
            data={openEvent}
            close={close}
            onSave={saveEventHandler}
            filterData={filterData}
          />
        )}
      </StyledPopup>
    </>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  /* height: calc(100vh - 100px - 40px); */

  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 50px 0px;
  }
  &-content {
    width: clamp(470px, 70vw, 650px);
  }
`;

export { Plan };
