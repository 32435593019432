const rules = {
  admin: {
    static: [
      "tab:CHANGE_PASSWORD",
      "tab:PLAN",

      "plan:searchByTeacher",
      "plan:searchByGroup",
      "plan:searchByLocation",

      "event:importFromLastWeek",
      "event:acceptWeek",

      "event:changeLocation",
      "event:changeTeacher",
      "event:changeGroup",
      "event:changeSubject",

      "event:viewRepeat",
      "event:changePaymentForm",
      "event:changeSubstituteTeacher",

      "event:delete",

      "tab:EDIT_DATA",
      "tab:EDIT_DATA/CONTRACTS",
      "tab:USERS",
      "user:add",
      "tab:EXCEPTIONS",

      // "tab:TEACHER_REPORT",
      "tab:REPORTS",
      "report:searchByTeacher",
      "remarks:edit",
    ],
    /**
     *
     * example of dynamic permission
     */
    // dynamic: {
    //   "event:add": ({ userId, eventId }) => {
    //     if (!userId || !eventId) return false;
    //     return userId === eventId;
    //   },
    // },
  },
  "qpay:financial": {
    static: [
      "tab:CHANGE_PASSWORD",
      "tab:PLAN",
      "tab:REPORTS",
      "report:searchByTeacher",
      "report:viewHoursWorked",

      "tab:USERS",
      "tab:EDIT_DATA",
      "tab:EDIT_DATA/CONTRACTS",

      //
    ],
  },

  "qpay:dataentry": {
    static: [
      "tab:CHANGE_PASSWORD",
      "tab:PLAN",
      "tab:EXCEPTIONS",
      "tab:EDIT_DATA",

      "plan:searchByTeacher",
      "plan:searchByGroup",
      "plan:searchByLocation",

      "event:importFromLastWeek",
      "event:acceptWeek",

      "event:changeLocation",
      "event:changeTeacher",
      "event:changeGroup",
      "event:changeSubject",

      "event:viewRepeat",
      "event:changeAbsenceReason",
      "event:changeAbsenceEffect",
      "event:changePaymentForm",
      "event:changeSubstituteTeacher",

      "event:delete",
      "tab:REPORTS",
      "report:searchByTeacher",
      "report:searchByDate",
      "remarks:edit",
    ],
  },

  "qpay:teacher": {
    static: [
      "tab:CHANGE_PASSWORD",
      "tab:PLAN",

      "event:acceptWeek",
      "event:changeLocation",
      "event:changeGroup",
      "event:changeSubject",
      "event:delete",

      "event:changeAbsenceReason",

      "tab:REPORTS",
      "remarks:edit",
    ],
  },
};

export default rules;

/**
 *
 * @param {Array} scopes
 * @param {String} action
 * @param {Object} data for dynamic condition
 * @returns Boolean
 */

const useRBAC = () => {
  const check = (scopes, action, data = null) => {
    //
    const avail_permissions = scopes.filter((scope) =>
      Object.keys(rules).includes(scope)
    );

    const staticPermissions = avail_permissions.reduce((obj, i) => {
      return [...obj, ...rules[i].static];
    }, []);

    const dynamicPermissions = avail_permissions.reduce((obj, i) => {
      if (rules[i].dynamic) return { ...obj, ...rules[i].dynamic };
      else {
        return obj;
      }
    }, {});

    if (staticPermissions && staticPermissions.includes(action)) {
      // static rule not provided for action
      return true;
    }

    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        // dynamic rule not provided for action
        return false;
      }
      return permissionCondition(data);
    }
    return false;
  };
  return { check };
};

export { useRBAC };
