import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Box, Button, Card, Flex, getPaletteColor } from "pcln-design-system";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "./Exceptions.scss";

import { useIsMounted, useQueryParams } from "utils";
import { DatePickerButton, Icon } from "components";
import { PulseLoader } from "react-spinners";

import { useExceptions } from "./useExceptions";
import { MultiSelect } from "components/SearchBar";
import { getEventConflicts, Event } from "services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { EXCEPTIONS } from "navigation/CONSTANTS";
import { useHistory } from "react-router";

const Exceptions = () => {
  const isMounted = useIsMounted();
  const conflictTypes = useSelector((state) => state.constants.conflictTypes);
  const { t } = useTranslation("translation", { keyPrefix: "exceptions" });
  const query = useQueryParams();
  const history = useHistory();

  const types = conflictTypes?.map((i) => ({
    id: i,
    name: t(`conflictTypes.${i}`),
  }));

  const [startDate, setStartDate] = useState(
    query.get("date")
      ? moment(query.get("date"), "YYYY-MM").toDate()
      : null || moment().toDate()
  );

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedTypes, setSelectedTypes] = useState(
    query
      .get("type")
      ?.split(",")
      ?.map((i) => ({
        id: i,
        name: t(`conflictTypes.${i}`),
      })) || []
  );

  const handleScopeSelectChange = (options) => {
    setSelectedTypes(options);
  };
  useEffect(() => {
    let types = "";
    let date = "";

    if (selectedTypes.length) {
      types = `type=${selectedTypes.map((i) => i.id)}`;
    }
    if (startDate) {
      date = `date=${moment(startDate).format("YYYY-MM")}`;
    }

    history.replace(
      `${EXCEPTIONS}?${types}${types !== "" ? `&${date}` : date}`
    );

    return () => {};
  }, [selectedTypes.length, startDate, selectedTypes, history]);

  const { gridConfig, columnDefinitions } = useExceptions({
    rowData,
    onDeleteEvent: (cell) => {
      Event.delete(cell.eventId)
        .then((res) => {
          if (isMounted()) {
            setRefresh(!refresh);
          }
        })
        .catch((err) => {
          console.log("axios err=", err);
          alert(err);
        });
    },
  });

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEventConflicts({
      startTime: moment(startDate).startOf("month").format('YYYY-MM-DDTHH:mm:ss'),
      endTime: moment(startDate).endOf("month").format('YYYY-MM-DDTHH:mm:ss'),
      conflictTypes: selectedTypes.map((i) => i.id),
    })
      .then((res) => {
        if (isMounted()) {
          setRowData(
            res.data.map((it) => ({
              ...it,
            }))
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("axios err=", err);
        if (isMounted()) setLoading(false);
      });

    return () => {};
  }, [startDate, selectedTypes, refresh, isMounted]);

  const onRefreshButton = () => {
    setRefresh(!refresh);
  };

  return (
    <SCard
      color="background.lightest"
      boxShadowSize="md"
      borderWidth={0}
      borderRadius={5}
    >
      <Flex
        width={1}
        height={"100%"}
        flexDirection="column"
        className={"container"}
      >
        <Flex width={1} p={2} justifyContent={"space-between"}>
          <Flex
            // width={1}
            flexDirection={"row"}
            p={2}
            px={3}
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <DatePickerButton
              onSelect={(date) => setStartDate(date)}
              value={startDate}
              showDate
              showMonthYearPicker
              format={"MM/YYYY"}
            />
            <Box px={4} />
            <MultiSelect
              data={types}
              value={selectedTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  minWidth: "420px",
                  backgroundColor: "#F4F5F6",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: "999",
                }),
              }}
              isMulti
              onSelection={handleScopeSelectChange}
            />
            <RefreshButton
              ml={3}
              onClick={onRefreshButton}
              color={"secondary"}
              variation={"plain"}
              title={"Odśwież"}
            >
              <Flex flexDirection="row">
                <Icon
                  name="Loading"
                  width="20px"
                  height="20px"
                  color={"secondary"}
                />
              </Flex>
            </RefreshButton>
          </Flex>
        </Flex>
        <div
          className="ag-custom-theme-tight-exceptions"
          style={{ height: "100%", width: "100%" }}
        >
          {!loading ? (
            <AgGridReact {...gridConfig}>
              {columnDefinitions.map((i, ind) => (
                <AgGridColumn {...i} key={ind} />
              ))}
            </AgGridReact>
          ) : (
            <Flex
              width={1}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PulseLoader color={"blue"} margin={10} />
            </Flex>
          )}
        </div>
      </Flex>
    </SCard>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  /* min-height: calc(100vh - 100px - 40px); */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const RefreshButton = styled(Button)`
  background-color: ${getPaletteColor("background.light")};
  &:hover {
    background-color: ${getPaletteColor("background.base")};
  }
`;

export { Exceptions };
