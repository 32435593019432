import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import { NOTE, REPORT, TEACHER_NOTE, TEACHER_REPORT } from "./CONSTANTS";

import history from "navigation";
import { LOGIN } from "navigation/CONSTANTS";
import { logout } from "services";

const out = () => {
  localStorage.removeItem("token");
  logout()
    .then((res) => {})
    .catch((err) => {});
  history.push(LOGIN, { from: { pathname: history.location.pathname } });
};

/**
 * Send GET request to get reports
 * @param String date
 * @returns Promise
 */
export const getReport = (date = null, employeeId = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(REPORT(), {
          params: {
            ...(date && { month: date }),
            ...(employeeId && { employeeId }),
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in getReport service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
export const getTeacherReport = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(TEACHER_REPORT())
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in getReport service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Report = {
  get: getReport,
  getTeacher: getTeacherReport,
};

/**
 * Send GET request to get teacher note
 * @param String date
 * @returns Promise
 */
export const getTeacherNote = ({ date }) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(TEACHER_NOTE(date))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in gettTeacherNote service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to edit teacher note
 * @param String date
 * @param String value
 * @returns Promise
 */
export const putTeacherNote = ({ date }, value) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(TEACHER_NOTE(date), JSON.stringify(value), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in putTeacherNote service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const TeacherNote = {
  get: getTeacherNote,
  put: putTeacherNote,
};

/**
 * Send GET request to get teacher note by admin
 * @param String date
 * @returns Promise
 */
export const getNote = ({ employeeId, date }) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(NOTE(employeeId, date))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in getNote service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to edit teacher note
 * @param String date
 * @param String value
 * @returns Promise
 */
export const putNote = ({ employeeId, date }, value) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(NOTE(employeeId, date), JSON.stringify(value), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error("Error in putNote service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Note = {
  get: getNote,
  put: putNote,
};
