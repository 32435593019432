// Example locale file for English, give this to your locale team to translate

export const AG_GRID_LOCALE_PL = {
    // Set Filter
    selectAll: '(Zaznacz wszystko)',
    selectAllSearchResults: '(Zaznacz wszystkie wyniki wyszukiwania)',
    searchOoo: 'Szukaj...',
    blanks: '(Puste)',
    noMatches: 'Brak wyników',

    // Number Filter & Text Filter
    filterOoo: 'Filtr...',
    equals: 'Równe',
    notEqual: 'Różne od',
    empty: 'Wybierz jeden',

    // Number Filter
    lessThan: 'Mniejsze od',
    greaterThan: 'Większe od',
    lessThanOrEqual: 'Mniejsze lub równe od',
    greaterThanOrEqual: 'Większe lub równe od',
    inRange: 'W zakresie',
    inRangeStart: 'od',
    inRangeEnd: 'do',

    // Text Filter
    contains: 'Zawiera',
    notContains: 'Nie zawiera',
    startsWith: 'Rozpoczyna się od',
    endsWith: 'Kończy się na',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'ORAZ',
    orCondition: 'LUB',

    // Filter Buttons
    applyFilter: 'Zastosuj',
    resetFilter: 'Resetuj',
    clearFilter: 'Wyczyść',
    cancelFilter: 'Anuluj',

    // Filter Titles
    textFilter: 'Filtr tekstu',
    numberFilter: 'Filtr liczbowy',
    dateFilter: 'Filtr daty',
    setFilter: 'Ustaw filtr',

    // Side Bar
    columns: 'Kolumny',
    filters: 'Filtry',

    // columns tool panel
    pivotMode: 'Tryb tabeli przestawnej',
    groups: 'Grupy wierszy',
    rowGroupColumnsEmptyMessage: 'Przeciągnij tutaj, aby ustawić grupy wierszy',
    values: 'Wartości',
    valueColumnsEmptyMessage: 'Przeciągnij tutaj, aby zagregować',
    pivots: 'Etykiety kolumn',
    pivotColumnsEmptyMessage: 'Przeciągnij tutaj, aby ustawić etykiety kolumn',

    // Header of the Default Group Column
    group: 'Grupa',

    // Other
    loadingOoo: 'Ładowanie...',
    noRowsToShow: 'Brak wierszy do wyświetlenia',
    enabled: 'Włączone',

    // Menu
    pinColumn: 'Przypnij kolumnę',
    pinLeft: 'Przypnij do lewej',
    pinRight: 'Przypnij do prawej',
    noPin: 'Odepnij',
    valueAggregation: 'Agregacja wartości',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Grupuj według',
    ungroupBy: 'Rozgrupuj według',
    resetColumns: 'Resetuj kolumny',
    expandAll: 'Rozwiń wszystkie',
    collapseAll: 'Zwiń wszystkie',
    copy: 'Kopiuj',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopiuj z nagłówkami',
    paste: 'Wklej',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'Export do CSV',
    excelExport: 'Export do Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Suma',
    min: 'Min',
    max: 'Max',
    none: 'Brak',
    count: 'Ilość',
    avg: 'Średnia',
    filteredRows: 'Przefiltrowane',
    selectedRows: 'Zaznaczone',
    totalRows: 'Łączna liczba wierszy',
    totalAndFilteredRows: 'Wiersze',
    more: 'Więcej',
    to: 'do',
    of: 'z',
    page: 'Strona',
    nextPage: 'Następna strona',
    lastPage: 'Ostatnia strona',
    firstPage: 'Pierwsza strona',
    previousPage: 'Poprzednia strona',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Wykres przestawny i tryb przestawny',
    pivotChart: 'Wykres przestawny',
    chartRange: 'Zakres wykresu',

    columnChart: 'Wykres kolumnowy',
    groupedColumn: 'Zgrupowane',
    stackedColumn: 'Wykres skumulowany kolumnowy',
    normalizedColumn: 'Wykres skumulowany kolumnowy normalizowany',

    barChart: 'Wykres słupkowy',
    groupedBar: 'Grouped',
    stackedBar: 'Wykres skumulowany słupkowy',
    normalizedBar: 'Wykres skumulowany spłupkowy normalizowany',

    pieChart: 'Wykres kołowy',
    pie: 'Wykres kołowy',
    doughnut: 'wykres pierścieniowy',

    line: 'Liniowy',

    xyChart: 'X Y (Wykres punktowy)',
    scatter: 'Wykres punktowy',
    bubble: 'Bąbelkowy',

    areaChart: 'Wykres warstwowy',
    area: 'Wykres warstwowy',
    stackedArea: 'Wykres warstwowy grupowany',
    normalizedArea: 'Wykres warstwowy grupowany normalizowany',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Wykres przestawny',
    rangeChartTitle: 'Wykres zasięgu',
    settings: 'Ustawienia',
    data: 'Dane',
    format: 'Format',
    categories: 'Kategorie',
    defaultCategory: '(Brak)',
    series: 'Seria',
    xyValues: 'Wartości X Y',
    paired: 'Tryb sparowany',
    axis: 'Oś',
    navigator: 'Nawigator',
    color: 'Kolor',
    thickness: 'Grubość',
    xType: 'Typ X',
    automatic: 'Automatyczny',
    category: 'Kategoria',
    number: 'Numer',
    time: 'Czas',
    xRotation: 'Rotacja X',
    yRotation: 'Rotacja Y',
    ticks: 'Tick',
    width: 'Szerokość',
    height: 'Wysokość',
    length: 'Długość',
    padding: 'Wypełnienie',
    spacing: 'Rozstaw',
    chart: 'Wykres',
    title: 'Tytuł',
    titlePlaceholder: 'tytuł wykresu - kliknij dwukrotnie aby edytować',
    background: 'Tło',
    font: 'Czcionka',
    top: 'Góra',
    right: 'Prawo',
    bottom: 'Dół',
    left: 'Lewo',
    labels: 'Etykiety',
    size: 'Wielkość',
    minSize: 'Minimalna wielkość',
    maxSize: 'Maximaln wielkość',
    legend: 'Legenda',
    position: 'Pozycja',
    markerSize: 'Wilekość znacznika',
    markerStroke: 'Obrys znacznika',
    markerPadding: 'Wypełnienie znacznika',
    itemSpacing: 'Odstępy między przedmiotami',
    itemPaddingX: 'Wypełnienie przedmiotu X',
    itemPaddingY: 'Wypełnienie przedmiotu Y',
    layoutHorizontalSpacing: 'Odstępy poziome',
    layoutVerticalSpacing: 'Odstępy pionowe',
    strokeWidth: 'Szerokość obrysu',
    offset: 'Przesunięcie',
    offsets: 'Przesunięcia',
    tooltips: 'Wskazówki',
    callout: 'Zawołanie',
    markers: 'Znaczniki',
    shadow: 'Cień',
    blur: 'Rozmazanie',
    xOffset: 'Przesunięcie X',
    yOffset: 'Przesunięcie Y',
    lineWidth: 'Szerokość liniii',
    normal: 'Normalna',
    bold: 'Pogrubiona',
    italic: 'Kursywa',
    boldItalic: 'Pogrubiona kursywa',
    predefined: 'Predefiniowane',
    fillOpacity: 'Przezroczystość wypełnienia',
    strokeOpacity: 'Przezroczystość linii',
    histogramBinCount: 'Bin count',
    columnGroup: 'Kolumna',
    barGroup: 'Słupek',
    pieGroup: 'Wykres kołowy',
    lineGroup: 'Linia',
    scatterGroup: 'X Y (Wykres punktowy)',
    areaGroup: 'Powierzchnia',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Zgrupowane',
    stackedColumnTooltip: 'Skumulowany',
    normalizedColumnTooltip: '100% skumulowany',
    groupedBarTooltip: 'Zgrupowane',
    stackedBarTooltip: 'Skumulowany',
    normalizedBarTooltip: '100% skumulowany',
    pieTooltip: 'Wykres kołowy',
    doughnutTooltip: 'Pierścieniowy',
    lineTooltip: 'Liniowy',
    groupedAreaTooltip: 'Powierzchniowy',
    stackedAreaTooltip: 'Skumulowany',
    normalizedAreaTooltip: '100% skumulowany',
    scatterTooltip: 'Wykres punktowy',
    bubbleTooltip: 'Bąbelkowy',
    histogramTooltip: 'Histogram',
    noDataToChart: 'Brak danych do sporządzenia wykresu',
    pivotChartRequiresPivotMode: 'Wykres przestawny wymaga włączonego trybu przestawnego',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Połączony z siatką',
    chartUnlinkToolbarTooltip: 'Rozłączony z siatką',
    chartDownloadToolbarTooltip: 'Pobierz wykres',

    // ARIA
    ariaHidden: 'ukryty',
    ariaVisible: 'widoczny',
    ariaChecked: 'zaznaczony',
    ariaUnchecked: 'niezaznaczony',
    ariaIndeterminate:'nieokreślony',
    ariaColumnSelectAll: "Przełącz 'Wybierz wszystkie kolumny'",
    ariaInputEditor: 'Edytor danych wejściowych',
    ariaDateFilterInput: 'Filtr daty',
    ariaFilterInput: 'Filtr',
    ariaFilterColumnsInput: 'Filtr kolumn',
    ariaFilterValue: 'Wartość filtra',
    ariaFilterFromValue: 'Filtr od',
    ariaFilterToValue: 'Filtr do',
    ariaFilteringOperator: 'Operator filtra',
    ariaColumnToggleVisibility: 'przełącz widoczność kolumn',
    ariaColumnGroupToggleVisibility: 'przełącz widoczność grup kolumn',
    ariaRowSelect: 'Naciśnij SPACJĘ, aby zaznaczyć ten wiersz',
    ariaRowDeselect: 'Naciśnij SPACJĘ, aby odznaczyć ten wiersz',
    ariaRowToggleSelection: 'Naciśnij spację, aby przełączyć zaznaczenie wiersza',
    ariaRowSelectAll: 'Naciśnij spację, aby przełączyć zaznaczenie wszystkich wierszy',
    ariaSearch: 'Szukaj',
    ariaSearchFilterValues: 'Wyszukaj wartości filtrów'
}