import moment from "moment";

export const events = [
  {
    start: "2021-10-26T08:00:00",
    end: "2021-10-26T08:45:00",
    backgroundColor: "#008FD5",
    data: {
      subject: null,
      location: null,
      attendees: [],
      teacher: {
        employeeId: 2,
        externalId: null,
        isActive: true,
        name: "Adam Kowalski",
        subjects: null,
        user: null,
      },

      substituteTeacher: null,
      eventId: 10,
      group: null,
      status: "New",

      paymentForm: "DodatkowoPlatne",
      absenceOutcome: "Przeniesiona",
      reasonForAbsence: "ZwolnienieLekarskie",

      startTime: "2021-10-26T08:00:00",
      endTime: "2021-10-26T08:45:00",
    },
  },
];

const hours = [
  {
    hour: 6,
    minute: 30,
  },
  {
    hour: 7,
    minute: 15,
  },
  {
    hour: 8,
    minute: 0,
  },
  {
    hour: 8,
    minute: 55,
  },
  {
    hour: 9,
    minute: 50,
  },
  {
    hour: 10,
    minute: 50,
  },
  {
    hour: 11,
    minute: 45,
  },
  {
    hour: 12,
    minute: 40,
  },
  {
    hour: 13,
    minute: 35,
  },
  {
    hour: 14,
    minute: 30,
  },
  {
    hour: 15,
    minute: 25,
  },
  {
    hour: 16,
    minute: 20,
  },
];

export const generateEmptyEvents = (filterData) => {
  const monday = moment(filterData.date);

  const emptyWeek = Array.from({ length: 5 }, (_, i) => i + 1)
    .map((day) => {
      return hours.map((it) => {
        const start = monday
          .clone()
          .set("weekday", day)
          .set("hours", it.hour)
          .set("minutes", it.minute);
        return {
          start: start.toDate(),
          end: start.clone().add(45, "minutes").toDate(),
        };
      });
    })
    .flat();

  return emptyWeek;
};
