import React, { useState } from "react";
import {
  Button,
  Card,
  Flex,
  Input,
  Text,
  Box,
  Label,
  Tooltip,
} from "pcln-design-system";

import { User } from "services";
import { PulseLoader } from "react-spinners";
import { useIsMounted } from "utils";

const SetPasswordPopup = ({ data, close }) => {
  const isMounted = useIsMounted();

  const [password, setPassword] = useState("");
  const [con_password, setCon_password] = useState("");

  const [loading, setLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    User.changePassword(data.userId, { password })
      .then((res) => {
        if (isMounted) setLoading(false);
        close();
      })
      .catch((err) => {
        if (isMounted) setLoading(false);
      });
  };

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <form onSubmit={onSubmit}>
        <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Text mt={2} mb={1} bold fontSize={3}>
              Dane nowego użytkownika
            </Text>
          </Flex>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="password">
                Hasło
              </Label>
              <Input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Box>
          </Flex>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Box width={3 / 4}>
              <Label mb={1} fontSize={1} htmlFor="con_password">
                Powtórz hasło
              </Label>
              <Input
                id="con_password"
                name="con_password"
                type="password"
                value={con_password}
                onChange={(event) => {
                  setCon_password(event.target.value);
                }}
              />
              {password !== con_password ? (
                <Tooltip color="error" bottom center>
                  Hasła nie zgadzają się
                </Tooltip>
              ) : null}
            </Box>
          </Flex>

          <Flex
            width={1}
            flexDirection={"row"}
            justifyContent="space-around"
            mt={2}
            mb={1}
          >
            <Button
              color={"primary"}
              variation="outline"
              onClick={close}
              type="button"
            >
              Anuluj
            </Button>
            <Button color={"secondary"} type="submit">
              <Flex flexDirection="row">
                {loading ? (
                  <PulseLoader size={10} color={"white"} />
                ) : (
                  <Text ml={2}>Dodaj</Text>
                )}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};

export default SetPasswordPopup;
