import React, { useEffect, useState } from "react";

import moment from "moment";
import styled from "styled-components";
import "./Reports.scss";

import { Box, Card, Flex } from "pcln-design-system";
import { Can, DatePickerButton, ThemedButton } from "components";
import { useIsMounted, useQueryParams } from "utils";
import { useHistory } from "react-router";
import { REPORTS } from "navigation/CONSTANTS";
import ControlledSearchBar from "pages/Plan/components/SearchBar";
import { useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { PulseLoader } from "react-spinners";
import { useReports } from "./useReports.js";
import { Report } from "services";
import Popup from "reactjs-popup";
import RemarksPopup from "./components/RemarksPopup";
import { useAuth } from "navigation/Auth/ProvideAuth";

const Reports = () => {
  const query = useQueryParams();
  const history = useHistory();
  const isMounted = useIsMounted();
  const auth = useAuth();
  const user = auth.user();
  const isTeacher = user.scope.includes("qpay:teacher");

  const teachers = useSelector((state) => state.constants.teachers);

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh] = useState(false);

  const [startDate, setStartDate] = useState(
    query.get("date")
      ? moment(query.get("date"), "YYYY-MM").toDate()
      : null || moment().toDate()
  );

  const { gridConfig, columnDefinitions } = useReports({
    onViewRemarks: (cell_data) => {
      setOpenRemarksPopup({
        date: startDate,
        ...cell_data,
      });
    },
    rowData,
    date: moment(startDate).format("YYYY-MM-DD"),
  });

  const [selectedTeacher, setSelectedTeacher] = useState(
    query.get("teacher")
      ? {
          id: query.get("teacher"),
        }
      : null
  );

  useEffect(() => {
    let date = "";
    let teacher = "";
    if (selectedTeacher) {
      teacher = `teacher=${selectedTeacher?.id}`;
    } else {
      if (startDate) {
        date = `date=${moment(startDate).format("YYYY-MM")}`;
      }
    }
    history.replace(`${REPORTS}?${teacher}${teacher !== "" ? "" : date}`);
    return () => {};
  }, [startDate, selectedTeacher, selectedTeacher?.id, history]);

  useEffect(
    () => {
      setLoading(true);

      if (isTeacher) {
        if (isMounted()) {
          Report.getTeacher()
            .then((res) => {
              if (isMounted()) {
                setRowData(
                  res.data
                    .map((it, ind) => ({
                      ...it,
                      employeeName: user.name,
                      id: ind,
                    }))
                    .sort((a, b) => b.month.localeCompare(a.month))
                );
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log("axios err=", err);
              if (isMounted()) setLoading(false);
            });
        }
      } else {
        Report.get(
          !selectedTeacher?.id && moment(startDate).format("YYYY-MM-DD"),
          selectedTeacher?.id
        )
          .then((res) => {
            if (isMounted()) {
              setRowData(
                res.data
                  .map((it, ind) => ({
                    ...it,
                    id: ind,
                  }))
                  .sort((a, b) => b.month.localeCompare(a.month))
              );
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("axios err=", err);
            if (isMounted()) setLoading(false);
          });
      }

      return () => {};
    },
    // eslint-disable-next-line
    [refresh, startDate, isMounted, selectedTeacher?.id, isTeacher]
  );

  const [openRemarksPopup, setOpenRemarksPopup] = useState(null);
  const closeRemarksPopup = () => {
    setOpenRemarksPopup(null);
  };

  const handleGetCollectiveReport = () => {
    if (isMounted()) {
      window.open(`/api/v1/report/${moment(startDate).format("YYYY-MM-DD")}`);
    }
  };

  return (
    <>
      <SCard
        color="background.lightest"
        boxShadowSize="md"
        borderWidth={0}
        borderRadius={5}
      >
        <Flex
          width={1}
          height={"100%"}
          flexDirection="column"
          className={"container"}
        >
          <Can
            perform={"report:searchByDate"}
            giveBoolean={true}
            ret={(valid) => (
              <Flex width={1} p={valid ? 2 : 0} justifyContent={"center"}>
                <Flex
                  width={1}
                  flexDirection={"row"}
                  p={valid ? 2 : 0}
                  px={3}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Can
                    perform={"report:searchByDate"}
                    yes={() => (
                      <DatePickerButton
                        onSelect={(date) => {
                          setSelectedTeacher(null);
                          setStartDate(date);
                        }}
                        value={startDate}
                        showDate
                        showMonthYearPicker
                        format={"MM/YYYY"}
                        isCleared={selectedTeacher?.id}
                      />
                    )}
                  />
                  <Box mx={3} />
                  <Can
                    perform={"report:searchByTeacher"}
                    yes={() => (
                      <ControlledSearchBar
                        width={"300px"}
                        isClearable={true}
                        value={teachers.filter(
                          ({ id }) =>
                            id.toString() === selectedTeacher?.id?.toString()
                        )}
                        data={teachers}
                        placeholder={"Nauczyciel..."}
                        onSelection={(data) => {
                          setSelectedTeacher(data);
                        }}
                      />
                    )}
                  />
                  <Box ml={"auto"} />
                  <Can
                    perform={"report:searchByDate"}
                    yes={() => (
                      <>
                        {!selectedTeacher?.id &&
                          moment(startDate).format("YYYY-MM-DD") && (
                            <ThemedButton
                              variation={"outline"}
                              onClick={handleGetCollectiveReport}
                            >
                              Raport zbiorczy
                            </ThemedButton>
                          )}
                      </>
                    )}
                  />
                </Flex>
              </Flex>
            )}
          />
          <div
            className="ag-custom-theme-tight-reports"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {!loading ? (
              <AgGridReact {...gridConfig}>
                {columnDefinitions.map((i, ind) => (
                  <AgGridColumn {...i} key={ind} />
                ))}
              </AgGridReact>
            ) : (
              <Flex
                width={1}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PulseLoader color={"blue"} margin={10} />
              </Flex>
            )}
          </div>
        </Flex>
      </SCard>
      <StyledPopup
        open={!!openRemarksPopup}
        closeOnDocumentClick
        onClose={closeRemarksPopup}
      >
        {(close) => <RemarksPopup data={openRemarksPopup} close={close} />}
      </StyledPopup>
    </>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 50px 0px;
  }
  &-content {
    width: clamp(470px, 70vw, 650px);
  }
`;

export { Reports };
