import React from "react";

// import { ROOT } from "./CONSTANTS";

export const NotFound = () => {
  return (
    <>
      {/* Page Not Found! */}

      <div>404: page not found!</div>
    </>
  );
};
