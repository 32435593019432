// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import { REMIND_PASSWORD, CHANGE_PASSWORD, CLAIMS, LOGIN, LOGOUT, TEACHER } from "./CONSTANTS";
/**
 * Login
 */

export const login = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(LOGIN(), data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
/**
 * Logout
 */

export const logout = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(LOGOUT(), {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in logout axios!");
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Claims
 */

export const claims = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(CLAIMS(), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const teacher = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(TEACHER(), {})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Password
 */

export const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(CHANGE_PASSWORD(), data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const remindPassword = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(REMIND_PASSWORD(), data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};
