import { useAuth } from "navigation/Auth/ProvideAuth";
import { useRBAC } from "utils/useRBAC";

const Can = ({
  perform,
  data,
  yes = () => null,
  no = () => null,
  giveBoolean = false,
  ret = () => null,
}) => {
  const { check } = useRBAC();
  const { user } = useAuth();

  if (giveBoolean) {
    return check(user().scope, perform, data) ? ret(true) : ret(false);
  }

  return check(user().scope, perform, data) ? yes(true) : no(false);
};

export default Can;
