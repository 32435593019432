import { Employee, Attendee, Subject, Room } from "services";

export const SET_CONSTANTS = "GET_CONSTANTS";
export const SET_TEACHERS = "GET_TEACHERS";
export const SET_GROUPS = "GET_GROUPS";
export const SET_ROOMS = "GET_ROOMS";
export const SET_SUBJECTS = "GET_SUBJECTS";

export const getConstants = (payload) => ({
  type: SET_CONSTANTS,
  payload,
});

export const setTeachers = (payload) => ({
  type: SET_TEACHERS,
  payload,
});

export const setGroups = (payload) => ({
  type: SET_GROUPS,
  payload,
});

export const setRooms = (payload) => ({
  type: SET_ROOMS,
  payload,
});

export const setSubjects = (payload) => ({
  type: SET_SUBJECTS,
  payload,
});

export const setConstants = () => {
  return (dispatch) =>
    Promise.all([
      dispatch(getConstants()),
      dispatch(fetchTeachers()),
      dispatch(fetchGroups()),
      dispatch(fetchSubjects()),
      dispatch(fetchRooms()),
    ]);
};

export const fetchTeachers = () => {
  return (dispatch) => {
    return Employee.get()
      .then((res) => {
        const data = res.data.data.results.map((i) => ({
          ...i,
          id: i.employeeId,
        }));

        dispatch(setTeachers(data));
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };
};

export const fetchGroups = () => {
  return (dispatch) => {
    return Attendee.get()
      .then((res) => {
        const data = res.data.data.results.map((i) => ({
          ...i,
          id: i.attendeeId,
        }));

        dispatch(setGroups(data));
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };
};

export const fetchSubjects = () => {
  return (dispatch) => {
    return Subject.get()
      .then((res) => {
        const data = res.data.data.results.map((i) => ({
          ...i,
          id: i.subjectId,
        }));

        dispatch(setSubjects(data));
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };
};

export const fetchRooms = () => {
  return (dispatch) => {
    return Room.get()
      .then((res) => {
        const data = res.data.data.results.map((i) => ({
          ...i,
          id: i.locationId,
        }));

        dispatch(setRooms(data));
      })
      .catch((err) => {
        console.log("axios err=", err);
      });
  };
};
