import React from "react";

import { Button, Card, Flex, Text } from "pcln-design-system";

const ErrorPopup = ({ data, close }) => {
  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        ></Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Text bold fontSize={3}>
            Wystąpił błąd
          </Text>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          px={3}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Flex
            width={1}
            flexDirection={"column"}
            justifyContent={"flex-start"}
          >
            <Text>
              Nie udało się utworzyć / edytować niektórych elementów:{" "}
            </Text>
            <ul>
              {data.map((i, index) => (
                <Text key={index}>{i.text}</Text>
              ))}
            </ul>
          </Flex>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          justifyContent="space-around"
          mt={1}
          mb={1}
        >
          <Button color={"secondary"} onClick={close}>
            Ok
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ErrorPopup;
