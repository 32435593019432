// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import { SCOPES, USER, USER_PASSWORD } from "./CONSTANTS";

import history from "navigation";
import { LOGIN } from "navigation/CONSTANTS";
import { logout } from "services";

const out = () => {
  localStorage.removeItem("token");
  logout()
    .then((res) => {})
    .catch((err) => {});
  history.push(LOGIN, { from: { pathname: history.location.pathname } });
};

/**
 * Function to fetch all the users.
 */

export const getUser = () => {
  try {
    const params = { page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(USER(), { params }).catch((err) => {
        if (err.response.status === 401) {
          out();
        }
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

/**
 * Send POST request to create new user
 * @param {Object} data
 * @returns Promise
 */

export const createUser = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(USER(), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error("Error in createUser service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send DELETE request to delete user
 * @param Number id
 * @returns Promise
 */

export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(USER(id))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error("Error in deleteUser service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to edit given user
 * @param {Number} id
 * @param {Object} data
 */
export const editUser = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(USER(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error("Error in editUser service!");
          if (err?.response?.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send GET request to get available scopes
 */
export const getScopes = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SCOPES())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error("Error in getScopes service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Send PUT request to change password for user
 */
export const changePassword = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(USER_PASSWORD(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error("Error in changePassword service!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const User = {
  get: getUser,
  post: createUser,
  put: editUser,
  delete: deleteUser,
  getScopes,
  changePassword,
};
