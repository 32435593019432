import React, { useState } from "react";
import { AG_GRID_LOCALE_PL } from "i18n/ag-grid/locale.pl";

import { useSelector } from "react-redux";
import { Button, Select } from "pcln-design-system";
import { useTranslation } from "react-i18next";

const GridCheckbox = (props) => {
  const checkedHandler = (event) => {
    props.setValue(event.target.checked);
  };
  return (
    <input
      style={{
        height: "15px",
        width: "15px",
        marginTop: "4px",
      }}
      type={"checkbox"}
      id={`checkbox-${props.rowIndex}`}
      checked={props.value}
      onChange={checkedHandler}
    />
  );
};

const GridSelect = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "edit_data" });
  const constants = useSelector(
    (state) => state.constants[props.valuesToSelect]
  );
  const checkedHandler = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Select
        py={0}
        type={"checkbox"}
        id={`checkbox-${props.rowIndex}`}
        value={props.value}
        onChange={checkedHandler}
      >
        {constants.map((typ) => (
          <option value={typ} key={typ}>
            {t(`contract.${typ}`)}
          </option>
        ))}
      </Select>
    </div>
  );
};

const PasswordResetLink = (props) => {
  return (
    <Button
      variation={"link"}
      disabled={props.data.userId < 100}
      onClick={() => {
        props.context.onResetPassword(props.data);
      }}
    >
      Ustaw
    </Button>
  );
};

const useUsers = ({ onResetPassword = () => {}, onCellEdit = () => {} }) => {
  const { t } = useTranslation("translation");
  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const [editedRows, setEditedRows] = useState([]);

  const cellValueChangedHandler = (event) => {
    if (editedRows.findIndex((row) => row.rowIndex === event.rowIndex) === -1) {
      setEditedRows([
        ...editedRows,
        { rowIndex: event.rowIndex, data: event.data },
      ]);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const gridConfig = {
    onGridReady: onGridReady,
    frameworkComponents: {
      BooleanCellRenderer: GridCheckbox,
      SelectCellRenderer: GridSelect,
      PasswordResetCellRenderer: PasswordResetLink,
    },
    context: { onResetPassword },

    localeText: AG_GRID_LOCALE_PL,
    stopEditingWhenCellsLoseFocus: true,
    onCellValueChanged: cellValueChangedHandler,
    suppressDragLeaveHidesColumns: true,
  };
  const columnDefinitions = () => {
    const columns = (cols = []) => [
      {
        field: `userId`,
        headerName: "Id",
        sortable: false,
        filter: true,
        resizable: false,
        flex: 0.2,
        checkboxSelection: false,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      {
        field: "userName",
        headerName: "Nazwa",
        sortable: true,
        filter: true,
        resizable: false,
        flex: 1,
        checkboxSelection: false,
        editable: true,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      ...cols,
      // {
      //   headerName: "Akcje",
      //   sortable: false,
      //   filter: false,
      //   resizable: false,
      //   flex: 0.5,
      //   checkboxSelection: false,
      //   editable: false,
      //   cellRenderer: "ButtonsCellRenderer",
      // },
    ];

    return columns([
      {
        field: "emailAddress",
        headerName: "Adres email",
        sortable: false,
        filter: true,
        resizable: false,
        flex: 1,
        checkboxSelection: false,
        editable: true,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      {
        field: "authenticationMethods",
        valueFormatter: (params) => {
          return params.value.length
            ? params.value.map((i) => t(`global.${i}`)).join(", ")
            : "";
        },
        headerName: "Metoda logowania",
        sortable: false,
        filter: true,
        resizable: false,
        flex: 1,
        checkboxSelection: false,
        editable: false,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ["apply", "clear", "reset", "cancel"],
        },
      },
      {
        field: "password",
        headerName: "Hasło",
        sortable: false,
        filter: false,
        resizable: false,
        flex: 1,
        checkboxSelection: false,
        editable: false,

        cellRenderer: "PasswordResetCellRenderer",
      },
    ]);
  };

  return {
    gridConfig,
    columnDefinitions,
    editedRows: { get: editedRows, set: setEditedRows },
  };
};

export { useUsers };
