export const ROOT = "/";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot_password";
export const CHANGE_PASSWORD = "/change_password";

//wwsse
export const PLAN = "/plan";
export const REPORTS = "/reports";
export const EXCEPTIONS = "/exceptions";
export const EDIT_DATA = "/edit-data";
export const CONTRACT = "/edit-data/contracts";

export const USERS = "/users";
