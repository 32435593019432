export const mapError = (response) => {
  switch (response.status) {
    case 401:
      return "Niepoprawne dane logowania";
    case 404:
      return "Niepoprawne zapytanie";
    case 429:
      return "Przekroczono limit ilości zapytań. Proszę chwilę poczekać";
    case 504:
      return "Nie można nawiązać połączenia z serwerem";
    default:
      return "Wystąpił błąd";
  }
};
