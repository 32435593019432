import React from "react";
import Popup from "reactjs-popup";

import { Box, getPaletteColor, Text } from "pcln-design-system";
import styled from "styled-components";

const StyledPopup = styled(Popup)`
  &-content {
    background: ${getPaletteColor("primary.dark")};
    border-radius: 5px;
    padding: 10px;
    transform: translate(0px, 4px);
  }
  &-arrow {
    color: ${getPaletteColor("primary.dark")};
  }
`;

const Tooltip = ({ children, text, ...rest }) => {
  return (
    <StyledPopup
      trigger={children}
      on={"hover"}
      closeOnDocumentClick
      mouseLeaveDelay={200}
      mouseEnterDelay={400}
      position="bottom right"
      arrow={true}
      {...rest}
    >
      <Box>
        <Text bold color={"text.lightest"}>
          {text}
        </Text>
      </Box>
    </StyledPopup>
  );
};
export default Tooltip;
