import React, { useEffect, useState } from "react";

import { Button, Card, Flex, Text } from "pcln-design-system";
import ControlledSearchBar from "pages/Plan/components/SearchBar";
import { Employee, User } from "services";
import { useIsMounted } from "utils";
import { PulseLoader } from "react-spinners";

const AssignUserPopup = ({ data, close, refresh }) => {
  const [userData, setUserData] = useState(null);
  const [users, setUsers] = useState([]);
  const isMounted = useIsMounted();

  useEffect(
    () => {
      User.get()
        .then((res) => {
          if (isMounted()) {
            setUsers(
              res.data.data.results
                .filter((i) => i.userId >= 100)
                .map((i) => ({
                  ...i,
                  name: i.userName,
                  id: i.userId,
                }))
            );
          }
        })
        .catch((err) => {
          console.log("axios err=", err);
        });

      return () => {};
    },
    // eslint-disable-next-line
    [isMounted]
  );

  const [loading, setLoading] = useState(false);
  const handleSaveButton = () => {
    if (userData?.userId) {
      setLoading(true);
      Employee.putUser(data.employeeId, { userId: userData.userId })
        .then((res) => {
          setLoading(false);
          refresh();
          close();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        ></Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Text bold fontSize={3}>
            Przypisz użytkownika:
          </Text>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Text fontSize={3}>
            Wybrany nauczyciel: <b>{data?.name}</b>
          </Text>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <ControlledSearchBar
            width={"500px"}
            value={users?.filter(
              ({ userId }) =>
                userId?.toString() === userData?.userId?.toString()
            )}
            data={users}
            placeholder={"Użytkownik..."}
            onSelection={(data) => {
              setUserData(data);
            }}
          />
        </Flex>

        <Flex
          width={1}
          flexDirection={"row"}
          justifyContent="space-around"
          mt={3}
          mb={1}
        >
          <Button color={"primary"} variation={"outline"} onClick={close}>
            Anuluj
          </Button>

          <Button
            ml={2}
            variation={"fill"}
            color="secondary"
            onClick={handleSaveButton}
          >
            <Flex flexDirection="row">
              {loading ? (
                <PulseLoader size={10} color={"white"} />
              ) : (
                <Text ml={2}>Zapisz</Text>
              )}
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AssignUserPopup;
