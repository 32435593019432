import { SYSTEM_ERROR } from "../config/CONSTANTS";
import axios from "axios";
import {
  EMPLOYEE,
  ATTENDEE,
  SUBJECT,
  LOCATION,
  EMPLOYEE_SUBJECT,
  EMPLOYEE_USER,
} from "./CONSTANTS";

import history from "navigation";
import { LOGIN } from "navigation/CONSTANTS";
import { logout } from "services";

const out = () => {
  localStorage.removeItem("token");
  logout()
    .then((res) => {})
    .catch((err) => {});
  history.push(LOGIN, { from: { pathname: history.location.pathname } });
};

const getEmployees = (id, par = {}) => {
  try {
    const params = { ...par, page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(EMPLOYEE(id), { params }).catch((err) => {
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

const getEmployee = (id, par = {}) => {
  try {
    const params = { ...par, page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(EMPLOYEE(id), { params }).catch((err) => {
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });

      return res;
    };
    return req(params);
  } catch (error) {}
};

const postEmployee = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(EMPLOYEE(), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in postEmployees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putEmployee = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(EMPLOYEE(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in putEmployees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const deleteEmployee = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(EMPLOYEE(id))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteEmployees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putEmployeeUser = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(EMPLOYEE_USER(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteEmployees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Employee = {
  get: getEmployees,
  getOne: getEmployee,
  post: postEmployee,
  put: putEmployee,
  delete: deleteEmployee,
  putUser: putEmployeeUser,
};
/**
 *
 * @param {Object} params
 */
const getEmployeeSubject = (par) => {
  try {
    const params = { page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios
        .get(EMPLOYEE_SUBJECT(par), { params })
        .catch((err) => {
          if (err.response.status === 401) {
            out();
          }
          if (err.response.status === 500) {
            throw new Error(`API error`);
          }
          throw new Error(`User not authorized`);
        });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

const postEmployeeSubject = (params, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(EMPLOYEE_SUBJECT(params), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in postEmployeeSubject axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putEmployeeSubject = (params, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(EMPLOYEE_SUBJECT(params), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in putEmployeeSubject axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const deleteEmployeeSubject = (params) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(EMPLOYEE_SUBJECT(params))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteEmployeeSubject axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const EmployeeSubject = {
  get: getEmployeeSubject,
  post: postEmployeeSubject,
  put: putEmployeeSubject,
  delete: deleteEmployeeSubject,
};

const getAttendee = () => {
  try {
    const params = { page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(ATTENDEE(), { params }).catch((err) => {
        if (err.response.status === 401) {
          out();
        }
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

const postAttendee = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(ATTENDEE(), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in postAttendees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putAttendee = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(ATTENDEE(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in putAttendees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const deleteAttendee = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(ATTENDEE(id))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteAttendees axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Attendee = {
  get: getAttendee,
  post: postAttendee,
  put: putAttendee,
  delete: deleteAttendee,
};

const getSubject = () => {
  try {
    const params = { page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(SUBJECT(), { params }).catch((err) => {
        if (err.response.status === 401) {
          out();
        }
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

const postSubject = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(SUBJECT(), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in getSubjects axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putSubject = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(SUBJECT(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in putSubjects axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const deleteSubject = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(SUBJECT(id))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteSubjects axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Subject = {
  get: getSubject,
  post: postSubject,
  put: putSubject,
  delete: deleteSubject,
};

const getRoom = () => {
  try {
    const params = { page: 1, pageSize: 100 };
    const req = async (
      params,
      prevResponse = { data: { data: { results: [] } } }
    ) => {
      const res = await axios.get(LOCATION(), { params }).catch((err) => {
        if (err.response.status === 401) {
          out();
        }
        if (err.response.status === 500) {
          throw new Error(`API error`);
        }
        throw new Error(`User not authorized`);
      });
      res.data.data.results = res.data.data.results.concat(
        prevResponse.data.data.results
      );

      if (res.data.data.pageCount > res.data.data.currentPage) {
        params.page++;
        return await req({ page: params.page, pageSize: 100 }, res);
      } else {
        return res;
      }
    };
    return req(params);
  } catch (error) {}
};

const postRoom = (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(LOCATION(), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in postLocation axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const putRoom = (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(LOCATION(id), data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in putLocation axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

const deleteRoom = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .delete(LOCATION(id))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("Error in deleteLocation axios!");
          if (err.response.status === 401) {
            out();
          }
          reject(err);
        });
    } catch (error) {
      reject(SYSTEM_ERROR);
    }
  });
};

export const Room = {
  get: getRoom,
  post: postRoom,
  put: putRoom,
  delete: deleteRoom,
};
