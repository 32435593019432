import React, { useState, forwardRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'components/Buttons/customDatePicker.scss'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

import pl from 'date-fns/locale/pl'
import { Button, Flex, Select, Text } from 'pcln-design-system'
import moment from 'moment'
import { range } from 'lodash'
registerLocale('pl', pl)
setDefaultLocale('pl')

const GridDatePicker = ({ onSelect, disabled, format = 'DD/MM/YYYY', value = null, ...rest }) => {
  const [date, setDate] = useState(value ? new Date(value) : new Date())

  const years = range(1990, new Date().getFullYear() + 4)
  const months = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ]

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      variation={'link'}
      title={'Wybór daty'}
      p={0}
      pt={2}
      px={2}
      pb={1}
      disabled={disabled}
      onClick={onClick}
      innerRef={ref}
    >
      <Flex mb={1}>
        <Text ml={2}>{moment(date).format(format)}</Text>
      </Flex>
    </Button>
  ))

  return (
    <DatePicker
      wrapperClassName={'react-datepicker-wrapper2'}
      selected={date}
      onChange={(date) => {
        setDate(date)
        rest.setValue(date)
      }}
      customInput={<ExampleCustomInput />}
      renderCustomHeader={({
        dateFromPicker,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button> */}
          <Select
            value={new Date(date).getFullYear()}
            onChange={({ target: { value } }) => {
              changeYear(value)
              setDate(new Date(date).setFullYear(value))
              rest.setValue(new Date(date).setFullYear(value))
            }}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>

          <Select
            value={months[new Date(date).getMonth()]}
            onChange={({ target: { value } }) => {
              changeMonth(months.indexOf(value))
              setDate(new Date(date).setMonth(months.indexOf(value)))
              rest.setValue(new Date(date).setMonth(months.indexOf(value)))
            }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>

          {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button> */}
        </div>
      )}
      withPortal
      portalId={'root-portal'}
      {...rest}
    />
  )
}

export { GridDatePicker }
