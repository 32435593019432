import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  getPaletteColor,
  Input,
  Label,
  Text,
} from "pcln-design-system";

import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "./ProvideAuth";
import { FORGOT_PASSWORD, PLAN } from "navigation/CONSTANTS";
import { PulseLoader } from "react-spinners";
import { mapError } from "utils/errorMapping";

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(true);
  const { from } =
    location.state?.from?.pathname !== "/login"
      ? location.state || { from: { pathname: PLAN } }
      : { from: { pathname: PLAN } };

  const forgotPassword = () => {
    history.push(FORGOT_PASSWORD);
  };

  const login = () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("login", email);
    formData.append("password", password);

    auth
      .signin(formData)
      .then((res) => {
        setIsLoading(false);
        history.push(from);

        // history.replace(from);
      })
      .catch((err) => {
        setError(mapError(err.response));
        setIsLoading(false);
      });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  useEffect(
    () => {
      if (auth.token) history.replace(PLAN);
      return () => {};
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Bg
      width={1}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      color={"background.light"}
    >
      <Flex
        width={1}
        justifyContent={"center"}
        my={3}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Card
          width={"450px"}
          color={"primary.darkest"}
          p={3}
          borderWidth={0}
          borderRadius={1}
          boxShadowSize={"lg"}
        >
          <form onSubmit={handleSubmit}>
            <Flex
              width={1}
              justifyContent={"space-around"}
              alignItems={"center"}
              mb={2}
            >
              <Logo src={"./QVX-logo.svg"} alt="logo" />
              <Text bold fontSize={4} color={"text.lightest"}>
                Menedżer
                <br /> planu zajęć
              </Text>
            </Flex>
            <Label htmlFor="email" color={"text.lightest"} fontSize={1}>
              Login:
            </Label>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Label
              mt={3}
              htmlFor="password"
              color={"text.lightest"}
              fontSize={1}
            >
              Hasło:
            </Label>
            <Input
              id="password"
              type={"password"}
              name="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            {error ? (
              <Text bold color={"error.base"}>
                {error}
              </Text>
            ) : null}

            <Flex
              mt={3}
              width={1}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <LinkButton
                variation={"link"}
                type="button"
                onClick={forgotPassword}
              >
                Zapomniałeś hasła?
              </LinkButton>
              <LoginButton
                // onClick={login}
                disabled={isLoading}
                type={"submit"}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  pt={isLoading ? 1 : 0}
                >
                  {isLoading ? (
                    <PulseLoader size={10} color={"white"} />
                  ) : (
                    ` Zaloguj się`
                  )}
                </Flex>
              </LoginButton>
            </Flex>
          </form>
        </Card>
      </Flex>
      {/* <button isloading={isLoading.toString()} onClick={login}>
        Log in
      </button> */}
    </Bg>
  );
};

const Bg = styled(Flex)`
  background: url("/bg.jpg") center center / cover no-repeat
    ${(props) => getPaletteColor(props.color)};
`;

const LinkButton = styled(Button)`
  color: ${getPaletteColor("text.lightest")};
  &:hover {
    color: ${getPaletteColor("text.lightest")};
  }
`;

const LoginButton = styled(Button)`
  background: ${getPaletteColor("primary.dark")};
  &:hover {
    background: ${getPaletteColor("primary.base")};
  }
  &:disabled {
    background: ${getPaletteColor("text.light")};
  }
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
  margin-left: 20px;
`;

export default Login;
