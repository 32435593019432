import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import BaseLayout from "components/BaseLayout";

import { NotFound } from "navigation/NotFound";

import {
  ROOT,
  PLAN,
  REPORTS,
  EXCEPTIONS,
  LOGIN,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  EDIT_DATA,
  USERS,
} from "navigation/CONSTANTS";

import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import ChangePassword from "./Auth/ChangePassword";
import PrivateRoute from "./Auth/PrivateRoute";

import Plan from "pages/Plan";
import Reports from "pages/Reports";
import Exceptions from "pages/Exceptions";
import EditData from "pages/EditData";
import Users from "pages/Users";

export const RouterConfig = () => {
  return (
    <Switch>
      {/* List all public routes here */}
      <Route exact path={ROOT}>
        <Redirect to={{ pathname: PLAN }} />
      </Route>
      <PrivateRoute exact path={PLAN} perform={"tab:PLAN"}>
        <BaseLayout>
          <Plan />
        </BaseLayout>
      </PrivateRoute>

      <PrivateRoute exact path={REPORTS} perform={"tab:REPORTS"}>
        <BaseLayout>
          <Reports />
        </BaseLayout>
      </PrivateRoute>

      <PrivateRoute exact path={EXCEPTIONS} perform={"tab:EXCEPTIONS"}>
        <BaseLayout>
          <Exceptions />
        </BaseLayout>
      </PrivateRoute>

      <PrivateRoute path={EDIT_DATA} perform={"tab:EDIT_DATA"}>
        <BaseLayout>
          <EditData />
        </BaseLayout>
      </PrivateRoute>

      <PrivateRoute exact path={USERS} perform={"tab:USERS"}>
        <BaseLayout>
          <Users />
        </BaseLayout>
      </PrivateRoute>

      <Route path={LOGIN}>
        <Login />
      </Route>
      <Route path={FORGOT_PASSWORD}>
        <ForgotPassword />
      </Route>

      <PrivateRoute
        exact
        path={CHANGE_PASSWORD}
        perform={"tab:CHANGE_PASSWORD"}
      >
        <ChangePassword />
      </PrivateRoute>

      {/* List a generic 404-Not Found route here */}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
