// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

import React, {
  useEffect /*, { createContext, useContext, useState }*/,
} from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import { LOGIN, PLAN } from "../CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroups,
  fetchRooms,
  fetchSubjects,
  fetchTeachers,
  getConstants,
} from "redux/actions/constantsActions";
import { useRBAC } from "utils/useRBAC";

function PrivateRoute({ children, perform, ...rest }) {
  let auth = useAuth();
  const { check } = useRBAC();
  const dispatch = useDispatch();
  const { teachers, rooms, groups, subjects } = useSelector(
    (state) => state.constants
  );

  useEffect(
    () => {
      if (auth.user()) {
        dispatch(getConstants());
        if (!teachers.length) {
          dispatch(fetchTeachers());
        }
        if (!rooms.length) {
          dispatch(fetchRooms());
        }
        if (!groups.length) {
          dispatch(fetchGroups());
        }
        if (!subjects.length) {
          dispatch(fetchSubjects());
        }
      }
      return () => {};
    },
    // eslint-disable-next-line
    [dispatch]
  );

  const user = auth.user();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? (
          check(user.scope, perform) ? (
            <>{children}</>
          ) : (
            <Redirect
              to={{
                pathname: PLAN,
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
export default PrivateRoute;
