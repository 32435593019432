/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";

import Select, { components } from "react-select";
import { Text } from "pcln-design-system";
import { Icon } from "components";

const ControlledSearchBar = ({
  data,
  value,
  inputValue,
  changeInputValue = () => {},
  placeholder = null,
  onSelection = () => {},
  currentSelection = [],
  showIcon = true,
  ...rest
}) => {
  const handleInputChange = (inputText, event) => {
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      changeInputValue(inputText);
    }
  };

  const onChange = (data, { action }) => {
    if (action === "select-option") {
      onSelection(data);
    }
    if (rest.isClearable)
      if (action === "clear") {
        onSelection(null);
      }
  };

  const filterOptions = (candidate, input) => {
    return candidate.label.match(new RegExp(input, "i"));
  };

  return (
    <Select
      components={{ Option, Control }}
      value={value}
      inputValue={inputValue}
      closeMenuOnSelect={true}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue === "") {
          return (
            <Text
              fontSize={1}
            >{`Wystąpił problem z pobieraniem wartości z serwera`}</Text>
          );
        } else
          return <Text fontSize={1}>{`Nie znaleziono: "${inputValue}"`}</Text>;
      }}
      onChange={onChange}
      onInputChange={handleInputChange}
      placeholder={placeholder || `Wyszukaj...`}
      loadingMessage={() => "Ładowanie..."}
      options={data}
      filterOption={filterOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isOptionDisabled={(option) => currentSelection.includes(option.name)}
      styles={myStyles}
      showIcon={showIcon}
      {...rest}
    />
  );
};

const Control = ({ children, ...props }) => {
  const style = { cursor: "pointer", margin: "10px 0px 5px 10px" };
  const { showIcon } = props.selectProps;

  return (
    <components.Control {...props}>
      {showIcon ? (
        <span style={style}>
          <Icon name="Search" width="20px" height="20px" color="primary.dark" />
        </span>
      ) : null}
      {children}
    </components.Control>
  );
};

const Option = ({ data, children, ...rest }) => {
  return (
    <components.Option {...rest}>
      {data.name} {data.lastName}
    </components.Option>
  );
};

const myStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    minWidth: state.selectProps.width || "200px",
    backgroundColor: "#F4F5F6",
    zIndex: "998",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export default ControlledSearchBar;
