import React, { useState } from 'react'
import { AG_GRID_LOCALE_PL } from 'i18n/ag-grid/locale.pl'

import { useSelector } from 'react-redux'
import { Button, Select } from 'pcln-design-system'
import { useTranslation } from 'react-i18next'
import ControlledSearchBar from 'pages/Plan/components/SearchBar'
import { GridDatePicker } from './GridDatePicker'

const GridCheckbox = (props) => {
  const checkedHandler = (event) => {
    props.setValue(event.target.checked)
  }
  return (
    <input
      style={{
        height: '15px',
        width: '15px',
        marginTop: '4px',
      }}
      type={'checkbox'}
      id={`checkbox-${props.rowIndex}`}
      checked={props.value}
      onChange={checkedHandler}
    />
  )
}

const GridSelect = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'edit_data' })
  const constants = useSelector((state) => state.constants[props.valuesToSelect])
  const checkedHandler = (event) => {
    props.setValue(event.target.value)
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Select py={0} type={'checkbox'} id={`checkbox-${props.rowIndex}`} value={props.value} onChange={checkedHandler}>
        {constants.map((typ) => (
          <option value={typ} key={typ}>
            {t(`contract.${typ}`)}
          </option>
        ))}
      </Select>
    </div>
  )
}

const GridLink = (cell) => {
  if (cell.data.id) return null
  else
    return (
      <Button
        variation={'link'}
        onClick={() => {
          cell.context.onNewCellDelete(cell.data)
        }}
      >
        Usuń
      </Button>
    )
}

const myStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    minWidth: state.selectProps.width || '200px',
    backgroundColor: '#F4F5F6',
    minHeight: '20px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

const GridSearch = (props) => {
  const data = useSelector((state) => state.constants[props.type])
  return (
    <ControlledSearchBar
      width={'200px'}
      isClearable={false}
      value={
        props.data.subject.subjectId
          ? Object.assign({}, props.data.subject, {
              id: props.data.subject.subjectId,
            })
          : null
      }
      data={data}
      placeholder={'Przedmiot...'}
      onSelection={(data) => {
        props.setValue(data)
      }}
      styles={myStyles}
      menuPortalTarget={document.body}
      menuPlacement={'auto'}
    />
  )
}

// const GridDatepicker = (props) => {
//   return (
//     <DatePickerButton
//       showDate
//       value={new Date(props.value)}
//       onSelect={(date) => {
//         props.setValue(date);
//       }}
//       withPortal
//       portalId="root-portal"
//     />
//   );
// };

const useEditContract = ({ onCellDelete = () => {}, onNewCellDelete = () => {} }) => {
  const [, setGridApi] = useState(null)
  const [, setGridColumnApi] = useState(null)

  const [editedRows, setEditedRows] = useState([])

  const cellValueChangedHandler = (event) => {
    if (editedRows.findIndex((row) => row.rowIndex === event.rowIndex) === -1) {
      setEditedRows([...editedRows, { rowIndex: event.rowIndex, data: event.data }])
    }
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const gridConfig = {
    onGridReady: onGridReady,
    frameworkComponents: {
      BooleanCellRenderer: GridCheckbox,
      SelectCellRenderer: GridSelect,
      ButtonsCellRenderer: GridLink,
      SearchCellRenderer: GridSearch,
      DatePickerRenderer: GridDatePicker,
    },
    context: { onCellDelete, onNewCellDelete },
    // rowHeight: 250,
    localeText: AG_GRID_LOCALE_PL,
    stopEditingWhenCellsLoseFocus: true,
    onCellValueChanged: cellValueChangedHandler,
    suppressDragLeaveHidesColumns: true,
  }
  const columnDefinitions = () => {
    const columns = (cols = []) => [
      // {
      //   field: `id`,
      //   headerName: "Id",
      //   sortable: false,
      //   filter: true,
      //   resizable: true,
      //   flex: 0.4,
      //   checkboxSelection: false,

      //   filterParams: {
      //     suppressAndOrCondition: true,
      //     closeOnApply: true,
      //     buttons: ["apply", "clear", "reset", "cancel"],
      //   },
      // },
      {
        valueGetter: (params) => {
          return params.data.name
        },
        headerName: 'Nauczyciel',
        sortable: true,
        filter: true,
        resizable: false,
        flex: 0.4,
        checkboxSelection: false,
        editable: false,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        valueGetter: (params) => {
          return params.data.subject
        },
        valueSetter: (params) => {
          params.data.subject = params.newValue
          return true
        },
        headerName: 'Przedmiot',
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0.6,
        checkboxSelection: false,
        editable: false,
        cellRenderer: 'SearchCellRenderer',
        cellRendererParams: { type: 'subjects' },

        cellStyle: { overflow: 'visible', lineHeight: '20px', zIndex: 50 },
        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        field: `validFrom`,
        headerName: 'Ważne od',
        sortable: false,
        filter: true,
        resizable: false,
        flex: 0.4,
        checkboxSelection: false,
        editable: false,

        cellRenderer: 'DatePickerRenderer',
        cellStyle: { overflow: 'visible', lineHeight: '20px', zIndex: 50 },
        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        field: `validTo`,
        headerName: 'Ważne do',
        sortable: false,
        filter: true,
        resizable: false,
        flex: 0.4,
        checkboxSelection: false,
        editable: false,
        cellRenderer: 'DatePickerRenderer',
        cellStyle: { overflow: 'visible', lineHeight: '20px', zIndex: 50 },

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        field: 'hourlyRate',
        headerName: 'Stawka za godzinę',
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0.35,
        checkboxSelection: false,
        editable: true,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        field: 'contractHours',
        headerName: 'Ilość godzin',
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0.25,
        checkboxSelection: false,
        editable: true,

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      {
        field: 'isActive',
        headerName: 'Aktywny',
        sortable: true,
        filter: true,
        resizable: false,
        flex: 0.25,
        checkboxSelection: false,
        editable: false,
        cellRenderer: 'BooleanCellRenderer',

        filterParams: {
          suppressAndOrCondition: true,
          closeOnApply: true,
          buttons: ['apply', 'clear', 'reset', 'cancel'],
        },
      },
      ...cols,
      {
        headerName: 'Akcje',
        sortable: false,
        filter: false,
        resizable: false,
        flex: 0.2,
        checkboxSelection: false,
        editable: false,
        cellRenderer: 'ButtonsCellRenderer',
      },
    ]

    return columns()
  }

  return {
    gridConfig,
    columnDefinitions,
    editedRows: { get: editedRows, set: setEditedRows },
  }
}

export { useEditContract }
