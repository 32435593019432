import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  getPaletteColor,
  Input,
  Label,
  Text,
} from "pcln-design-system";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "./ProvideAuth";
import { LOGIN, PLAN } from "navigation/CONSTANTS";
import { PulseLoader } from "react-spinners";
import { remindPassword } from "services";

const ForgotPassword = () => {
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const goToLogin = () => {
    history.replace(LOGIN);
  };

  const [login, setLogin] = useState("");

  const forgotPassword = () => {
    setIsLoading(true);
    console.log("SS:: reset password btn clicked...");

    const formData = new FormData();
    formData.append("login", login);
    remindPassword(formData)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          alert("Wiadomość została wysłana");
          goToLogin();
        } else {
          alert(res.messages[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Wystąpił błąd");
        setIsLoading(false);
      });
  };

  useEffect(
    () => {
      if (auth.user()) history.replace(PLAN);
      return () => {};
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Bg
      width={1}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      color={"background.light"}
    >
      <Flex
        width={1}
        justifyContent={"center"}
        my={3}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Card
          width={"450px"}
          color={"primary.darkest"}
          p={3}
          borderWidth={0}
          borderRadius={1}
          boxShadowSize={"lg"}
        >
          <Flex
            width={1}
            justifyContent={"space-around"}
            alignItems={"center"}
            mb={2}
          >
            <Logo src={"./QVX-logo.svg"} alt="logo" />
            <Text bold fontSize={4} color={"text.lightest"}>
              Menedżer
              <br /> planu zajęć
            </Text>
          </Flex>
          <Label htmlFor="email" color={"text.lightest"} fontSize={1}>
            Login:
          </Label>
          <Input
            id="email"
            name="email"
            value={login}
            onChange={(event) => setLogin(event.target.value)}
          />
          <Flex
            mt={3}
            width={1}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <LinkButton variation={"link"} onClick={goToLogin}>
              Powrót do logowania
            </LinkButton>
            <LoginButton onClick={forgotPassword} disabled={isLoading}>
              <Flex
                justifyContent="center"
                alignItems="center"
                pt={isLoading ? 1 : 0}
              >
                {isLoading ? (
                  <PulseLoader size={10} color={"white"} />
                ) : (
                  `Przypomnij hasło`
                )}
              </Flex>
            </LoginButton>
          </Flex>
        </Card>
      </Flex>
    </Bg>
  );
};

const Bg = styled(Flex)`
  background: url("/bg.jpg") center center / cover no-repeat
    ${(props) => getPaletteColor(props.color)};
`;

const LinkButton = styled(Button)`
  color: ${getPaletteColor("text.lightest")};
  &:hover {
    color: ${getPaletteColor("text.lightest")};
  }
`;

const LoginButton = styled(Button)`
  background-color: ${getPaletteColor("primary.dark")};
  &:hover {
    background: ${getPaletteColor("primary.base")};
  }
  &:disabled {
    background: ${getPaletteColor("text.light")};
  }
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
  margin-left: 20px;
`;

export default ForgotPassword;
