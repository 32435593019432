/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";

import Select, { components } from "react-select";
import { Text } from "pcln-design-system";
import { Icon } from "components";

const isLoading = false;

const SearchBar = ({
  data,
  placeholder = null,
  onSelection = () => {},
  currentSelection = [],
  defaultValue = null,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (inputText, event) => {
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setInputValue(inputText);
    }
  };

  const onChange = (data, { action }) => {
    if (action === "select-option") {
      setValue(data);
      onSelection(data);
    }
  };

  const filterOptions = (candidate, input) => {
    return candidate.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Select
      components={{ Option, Control }}
      value={value}
      inputValue={inputValue}
      closeMenuOnSelect={true}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue === "") {
          return (
            <Text
              fontSize={1}
            >{`Wystąpił problem z pobieraniem wartości z serwera`}</Text>
          );
        } else
          return <Text fontSize={1}>{`Nie znaleziono: "${inputValue}"`}</Text>;
      }}
      onChange={onChange}
      onInputChange={handleInputChange}
      placeholder={placeholder || `Wyszukaj...`}
      isLoading={isLoading}
      loadingMessage={() => "Ładowanie..."}
      options={data}
      filterOption={filterOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isOptionDisabled={(option) => currentSelection.includes(option.name)}
      styles={myStyles}
      menuPlacement={"auto"}
      {...rest}
    />
  );
};

const Control = ({ children, ...props }) => {
  const style = { cursor: "pointer", margin: "10px 0px 5px 10px" };

  return (
    <components.Control {...props}>
      <span style={style}>
        <Icon name="Search" width="20px" height="20px" color="primary.dark" />
      </span>
      {children}
    </components.Control>
  );
};

const Option = ({ data, children, ...rest }) => {
  return (
    <components.Option {...rest}>
      {data.name} {data.lastName}
    </components.Option>
  );
};

const MultiSelect = ({ placeholder, data, onSelection, ...rest }) => {
  const filterOptions = (candidate, input) => {
    return candidate.label.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Select
      components={{ Option, Control }}
      closeMenuOnSelect={true}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue === "") {
          return <Text fontSize={1}>{`Brak danych`}</Text>;
        } else
          return <Text fontSize={1}>{`Nie znaleziono: "${inputValue}"`}</Text>;
      }}
      placeholder={placeholder || `Wyszukaj...`}
      isLoading={isLoading}
      loadingMessage={() => "Ładowanie..."}
      options={data}
      filterOption={filterOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      styles={myStyles}
      onChange={(data, action) => onSelection(data, action)}
      menuPlacement={"auto"}
      {...rest}
    />
  );
};

const myStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    minWidth: "220px",
    backgroundColor: "#F4F5F6",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};

export default SearchBar;

export { MultiSelect };
