import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Button, Card, Flex, Select, Text } from "pcln-design-system";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "./EditData.scss";

import { useIsMounted, useQueryParams } from "utils";
import { PulseLoader } from "react-spinners";

import { useEditData } from "./useEditData";
import { Icon } from "components";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import ErrorPopup from "./components/ErrorPopup";
import { setConstants } from "redux/actions/constantsActions";
import { useHistory } from "react-router";
import { EDIT_DATA } from "navigation/CONSTANTS";
import AssignUserPopup from "./components/AssignUserPopup";

const EditData = () => {
  const { contractType } = useSelector((state) => state.constants);
  let query = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);

  const { data_types, columnDefinitions, gridConfig, editedRows } = useEditData(
    {
      onCellDelete: (cell_data) => {
        data_types[selectedData].func
          .delete(cell_data[`${selectedData}Id`])
          .then(() => setRefresh(!refresh))
          .catch((err) => {
            setErrorArray([
              ...errorArray,
              {
                resData: err.response,
                text: (
                  <span>
                    Nie udało się usunąć elementu: <b>{cell_data.name}</b>
                  </span>
                ),
              },
            ]);
            setOpenErrorPopup(true);
          });
      },
      onNewCellDelete: (cell_data) => {
        const newRowData = rowData.slice().filter((i) => i.id !== cell_data.id);
        setRowData(newRowData);
      },
      assignUserToEmployee: (cell_data) => {
        setAssignUserData(cell_data);
        setOpenAssignUserPopup(true);
      },
      rowData: rowData,
    }
  );

  const keys = Object.keys(data_types);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [selectedData, setSelectedData] = useState(
    query.get("type") || data_types[keys[0]].value
  );

  useEffect(() => {
    if (selectedData) history.replace(`${EDIT_DATA}?type=${selectedData}`);
    return () => {};
  }, [selectedData, history]);

  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const closeErrorPopup = () => {
    setOpenErrorPopup(false);
    setErrorArray([]);
  };

  const [openAssignUserPopup, setOpenAssignUserPopup] = useState(false);
  const [assignUserData, setAssignUserData] = useState(null);
  const closeAssignUserPopup = () => {
    setOpenAssignUserPopup(false);
    setAssignUserData(null);
    // setRefresh(!refresh);
  };

  const isMounted = useIsMounted();

  const default_objects = {
    employee: {
      contractType: contractType?.[0] || "Etat",
      employeeId: null,
      id: Math.max(...rowData.map((o) => o.id)) + 1,
      externalId: null,
      isActive: false,
      name: null,
      subjects: [],
      user: null,
    },
    attendee: {
      attendeeId: null,
      id: Math.max(...rowData.map((o) => o.id)) + 1,
      externalId: null,
      isActive: false,
      name: null,
    },
    subject: {
      subjectId: null,
      id: Math.max(...rowData.map((o) => o.id)) + 1,
      isActive: false,
      name: null,
      parentSubjectId: null,
      type: null,
    },
    location: {
      locationId: null,
      id: Math.max(...rowData.map((o) => o.id)) + 1,
      externalId: null,
      isActive: false,
      name: null,
    },
  };

  useEffect(
    () => {
      setLoading(true);
      editedRows.set([]);
      data_types[selectedData].func
        .get()
        .then((res) => {
          if (isMounted()) {
            setRowData(
              res.data.data.results.map((it, ind) => ({ ...it, id: ind }))
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("axios err=", err);
          if (isMounted()) setLoading(false);
        });

      return () => {};
    },
    // eslint-disable-next-line
    [selectedData, refresh, isMounted]
  );

  const [triggerSave, setTriggerSave] = useState(false);
  useEffect(
    () => {
      if (editedRows.get.length) {
        setLoading(true);
        Promise.allSettled(
          editedRows.get.map((row) => {
            if (row.data[`${selectedData}Id`]) {
              return data_types[selectedData].func.put(
                row.data[`${selectedData}Id`],
                row.data
              );
            } else {
              return data_types[selectedData].func.post(row.data);
            }
          })
        ).then((responses) => {
          responses.forEach((res, index) => {
            if (res?.status === "rejected")
              setErrorArray([
                ...errorArray,
                {
                  text: <b>{editedRows.get[index].data.name}</b>,
                  rowIndex: editedRows.get[index].rowIndex,
                  resData: res.reason.response.data,
                },
              ]);
          });

          if (responses.filter((r) => r.status === "rejected").length)
            setOpenErrorPopup(true);

          setLoading(false);
          dispatch(setConstants());
          setRefresh(!refresh);
        });
      }
      return () => {};
    },
    // eslint-disable-next-line
    [triggerSave, isMounted]
  );

  const handleSaveButton = () => {
    setTriggerSave(!triggerSave);
  };

  return (
    <>
      <SCard
        color="background.lightest"
        boxShadowSize="md"
        borderWidth={0}
        borderRadius={5}
      >
        <Flex
          width={1}
          height={"100%"}
          flexDirection="column"
          className={"container"}
        >
          <Flex
            width={1}
            p={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Flex alignItems={"center"} mr={2}>
              <Text
                bold
                ml={2}
                mr={3}
                style={{ whiteSpace: "nowrap" }}
                fontSize={2}
                color={"text.light"}
              >
                Wybierz dane do wyświetlenia / edycji:
              </Text>
              <StyledSelect
                id="data_type"
                name="data_type"
                value={selectedData}
                onChange={(event) => {
                  setSelectedData(event.target.value);
                }}
              >
                {keys.map((i) => (
                  <option value={data_types[i].value} key={i}>
                    {data_types[i].text}
                  </option>
                ))}
              </StyledSelect>
            </Flex>
            <Flex ml={3} alignItems={"center"}>
              <Button
                variation={"outline"}
                onClick={() => {
                  setRowData([...rowData, default_objects[selectedData]]);
                }}
              >
                <Flex flexDirection="row">
                  <Icon
                    name="Plus"
                    width="20px"
                    height="20px"
                    color={"primary.base"}
                  />
                  <Text ml={2}> Utwórz nowy</Text>
                </Flex>
              </Button>
              <Button
                ml={2}
                variation={"fill"}
                color="secondary"
                disabled={!editedRows.get.length}
                onClick={() => {
                  handleSaveButton();
                }}
              >
                <Flex flexDirection="row">
                  {loading ? (
                    <PulseLoader size={10} color={"white"} />
                  ) : (
                    <>
                      <Icon
                        name="Diskette"
                        width="20px"
                        height="20px"
                        color={
                          !editedRows.get.length
                            ? "text.light"
                            : "primary.lightest"
                        }
                      />
                      <Text ml={2}>Zapisz</Text>
                    </>
                  )}
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <div
            className="ag-custom-theme-tight-edit-data"
            style={{ height: "100%", width: "100%" }}
          >
            {!loading ? (
              <AgGridReact {...gridConfig}>
                {columnDefinitions(selectedData).map((col, index) => (
                  <AgGridColumn {...col} key={index} />
                ))}
              </AgGridReact>
            ) : (
              <Flex
                width={1}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PulseLoader color={"blue"} margin={10} />
              </Flex>
            )}
          </div>
        </Flex>
      </SCard>
      <StyledPopup
        open={openErrorPopup}
        closeOnDocumentClick
        onClose={closeErrorPopup}
      >
        {(close) => <ErrorPopup data={errorArray} close={close} />}
      </StyledPopup>
      <StyledPopup
        open={openAssignUserPopup}
        closeOnDocumentClick
        onClose={closeAssignUserPopup}
      >
        {(close) => (
          <AssignUserPopup
            data={assignUserData}
            close={close}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </StyledPopup>
    </>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  /* min-height: calc(100vh - 100px - 40px); */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const StyledSelect = styled(Select)`
  padding: 5px 10px;
  width: 200px;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 50px 0px;
  }
  &-content {
    width: clamp(470px, 70vw, 650px);
  }
`;

export { EditData };
