/**
 * All API urls and other constants will reside here.
 */

const v = `v1`;

export const GET_ALL_USERS = () => `/data/users`;
export const GET_USER_DETAILS = (id) => `/api/data/${id}`;

export const TEST = () => `/api/v1/test`;

export const GET_PLAN = () => `/api/v1/posts`;
export const GET_PLAN_DETAIL = (id) => `/api/v1/getPlan/${id}`;

export const GET_CONSTANTS = () => `/api/v1/users`;

export const POST_PLAN = () => `/api/v1/todos/`;

/**
 * Auth
 *
 */
export const LOGIN = () => `/api/${v}/auth/login`;
export const LOGOUT = () => `/api/${v}/auth/logout`;
export const CLAIMS = () => `/api/${v}/auth/claims`;
export const CHANGE_PASSWORD = () => `/api/${v}/auth/password`;
export const REMIND_PASSWORD = () => `/api/${v}/auth/remind-password`;

/**
 * Employee
 *
 */
export const EMPLOYEE = (id = null) => `/api/${v}/employee/${id ? id : ""}`;
export const EMPLOYEE_USER = (id) => `/api/${v}/employee/${id}/user`;

/**
 * Employee - subject
 *
 */
export const EMPLOYEE_SUBJECT = ({
  employeeId = "",
  subjectId = "",
  validfrom = null,
  validto = null,
}) =>
  `/api/${v}/employee/${employeeId}/subject/${subjectId}${
    validfrom ? "/" + validfrom : ""
  }${validto ? "/" + validto : ""}`;

/**
 * Attendee
 *
 */

export const ATTENDEE = (id = "") => `/api/${v}/attendee/${id}`;

/**
 * Subject
 *
 */

export const SUBJECT = (id = "") => `/api/${v}/subject/${id}`;

export const LOCATION = (id = "") => `/api/${v}/location/${id}`;

/**
 * Events
 *
 */

export const EVENT = (id = "") => `/api/${v}/event/${id}`;
export const COPY_EVENTS = () => `/api/${v}/event/copy`;
export const ACCEPT_EVENTS = () => `/api/${v}/event/accept-events`;

export const EVENT_CONFLICTS = () => `/api/${v}/event/conflicts`;

/**
 * User
 *
 */

export const USER = (id = "") => `/api/${v}/user/${id}`;
export const USER_PASSWORD = (id = "") => `/api/${v}/user/${id}/password`;
export const SCOPES = () => `/api/${v}/user/available-scopes`;

/**
 * Teacher
 *
 */
export const TEACHER = () => `/api/${v}/teacher`;

export const TEACHER_EVENT = (id = "") => `/api/${v}/teacher/event/${id}`;

export const TEACHER_CONFIRM_EVENTS = () => `/api/${v}/teacher/confirm-events`;

/**
 * Report
 *
 */

export const REPORT = () => `/api/${v}/report`;

export const TEACHER_REPORT = () => `/api/${v}/teacher/report`;

export const TEACHER_NOTE = (date = "") =>
  `/api/${v}/teacher/note/${date !== "" ? date : ""}`;

export const NOTE = (employeeId = null, date = "") =>
  `/api/${v}/employee/${employeeId}/note/${date !== "" ? date : ""}`;
