import React from "react";

import { Button, Text, Flex, getPaletteColor } from "pcln-design-system";

import styled from "styled-components";
import Icon from "components/Icon";

const Container = styled(Flex)`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px ${getPaletteColor("border.base")};
  width: auto;
  min-width: 100px;
`;

const MyButton = styled(Button)`
  padding: 5px 0px 0px 0px;

  &:focus {
    outline: none;
    box-shadow: unset;
  }
`;

const LIcon = styled(Icon)`
  /* transform: scaleX(-1); */
`;
const RIcon = styled(Icon)`
  /* transform: rotate(0deg); */
`;

const GroupedButton = ({
  children,
  iconSize = 30,
  onLeft,
  onRight,
  color,
  IconL,
  IconR,
  disabled,
  ...rest
}) => {
  return (
    <Container
      color={color}
      borderRadius={30}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      {...rest}
    >
      <MyButton variation="plain" onClick={onLeft} disabled={disabled}>
        <LIcon
          name="Left_arrow_1"
          width="25px"
          height="25px"
          color={"primary.dark"}
        />
      </MyButton>
      <Text
        bold
        fontSize={2}
        textAlign="center"
        m={1}
        style={{ userSelect: "none" }}
      >
        {children}
      </Text>
      <MyButton variation="plain" onClick={onRight} disabled={disabled}>
        <RIcon
          name="Right_arrow"
          width="25px"
          height="25px"
          color={"primary.dark"}
        />
      </MyButton>
    </Container>
  );
};

const ThemeButton = styled(Button)`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px ${getPaletteColor("border.base")};
  color: ${getPaletteColor("text.dark")};
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${getPaletteColor("border.base")};
  }
`;

const ThemedButton = ({ children, ...rest }) => {
  return (
    <ThemeButton {...rest}>
      <Text bold fontSize={2} textAlign="center">
        {children}
      </Text>
    </ThemeButton>
  );
};

const LinkedButton = styled(Button)`
  overflow: hidden;
  box-shadow: unset;
  &:focus {
    outline: none;
    box-shadow: unset;
  }
`;

const LinkButton = ({ children, ...rest }) => {
  return (
    <LinkedButton variation={"link"} {...rest}>
      <Text bold fontSize={2} textAlign="center" color={"text.dark"}>
        {children}
      </Text>
    </LinkedButton>
  );
};

export { GroupedButton, ThemedButton, LinkButton };
