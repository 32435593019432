import React, { useState, useEffect } from "react";
import { Button, Card, Flex, Text, Box, TextArea } from "pcln-design-system";

import { PulseLoader } from "react-spinners";
import { useIsMounted } from "utils";
import { Can } from "components";
import { Note, TeacherNote } from "services";
import moment from "moment";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { useTranslation } from "react-i18next";

const RemarksPopup = ({ close, data }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth.user();
  const isTeacher = user.scope.includes("qpay:teacher");

  const [remarksData, setRemarksData] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();

    let Instance = isTeacher ? TeacherNote : Note;
    const params = {
      ...(isTeacher ? {} : { employeeId: data.employeeId }),
      date: moment(data.date).format("YYYY-MM-DD"),
    };

    setLoading(true);
    Instance.put(params, remarksData)
      .then((res) => {
        if (isMounted) setLoading(false);
        close();
      })
      .catch((err) => {
        alert(t("global.errors.network_event_error"));
        if (isMounted) setLoading(false);
      });
  };

  useEffect(
    () => {
      let Instance = isTeacher ? TeacherNote : Note;
      const params = {
        ...(isTeacher ? {} : { employeeId: data.employeeId }),
        date: moment(data.date).format("YYYY-MM-DD"),
      };

      setInitialLoading(true);
      Instance.get(params)
        .then((res) => {
          if (isMounted) {
            setRemarksData(res?.data);
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setError("Nie można połączyć z serwerem");
          if (isMounted) setInitialLoading(false);
        });
      return () => {};
    },
    //eslint-disable-next-line
    []
  );

  const [error, setError] = useState(null);

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <form onSubmit={onSubmit}>
        <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Text mt={2} mb={1} bold fontSize={3}>
              {`Uwagi do raportu miesięcznego ${moment(data.date).format(
                "MM-YYYY"
              )}`}
            </Text>
          </Flex>
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Can
              perform={"remarks:edit"}
              giveBoolean={true}
              ret={(valid) => (
                <Box width={1}>
                  {!initialLoading ? (
                    error ? (
                      <Flex
                        width={1}
                        flexDirection={"row"}
                        p={2}
                        justifyContent={"space-around"}
                        alignItems="center"
                      >
                        <Text bold color={"error.base"}>
                          {error}
                        </Text>
                      </Flex>
                    ) : (
                      <TextArea
                        id="name"
                        style={{ height: 300, resize: "vertical" }}
                        disabled={!valid}
                        name="name"
                        value={remarksData}
                        onChange={(e) => setRemarksData(e.target.value)}
                      />
                    )
                  ) : (
                    <Flex
                      height={300}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <PulseLoader size={10} color={"blue"} />
                    </Flex>
                  )}
                </Box>
              )}
            />
          </Flex>

          <Flex
            width={1}
            flexDirection={"row"}
            justifyContent="space-around"
            mt={2}
            mb={1}
          >
            <Button
              color={"primary"}
              variation="outline"
              onClick={close}
              type="button"
            >
              Anuluj
            </Button>
            <Can
              perform={"remarks:edit"}
              yes={() => (
                <Button color={"secondary"} type="submit" disabled={!!error}>
                  <Flex flexDirection="row">
                    {loading ? (
                      <PulseLoader size={10} color={"white"} />
                    ) : (
                      <Text ml={2}>Zapisz</Text>
                    )}
                  </Flex>
                </Button>
              )}
            />
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};

export default RemarksPopup;
