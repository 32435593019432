import { Can, SearchBar } from "components";
import { MultiSelect } from "components/SearchBar";
import moment from "moment";
import { useAuth } from "navigation/Auth/ProvideAuth";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Label,
  Text,
  Select,
} from "pcln-design-system";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Event, TeacherEvent } from "services";
import styled from "styled-components";
import { useIsMounted } from "utils";

const EditLesson = ({ data, close, onSave = () => {}, filterData }) => {
  const {
    teachers: tt,
    groups: g,
    rooms: r,
    subjects: s,
    absency_reasons,
    absency_effects,
    paymentForm,
  } = useSelector((state) => state.constants);
  const teachers = tt.filter((i) => i.isActive);
  const groups = g.filter((i) => i.isActive);
  const rooms = r.filter((i) => i.isActive);
  const subjects = s.filter((i) => i.isActive);

  const { t } = useTranslation("translation");

  const [eventData, setEventData] = useState(
    data.extendedProps.data
      ? {
          subject: null,
          room:
            filterData.type === "room"
              ? rooms.find((i) => i.id === filterData.value.id)
              : null,
          group:
            filterData.type === "group"
              ? groups.filter((i) => i.id === filterData.value.id)
              : [],
          teacher:
            filterData.type === "teacher"
              ? teachers.find((i) => i.id === filterData.value.id)
              : data.extendedProps.data.teacher,
          absence: false,
          absenceReason: absency_reasons[0],
          absenceEffect: absency_effects[0],
          paymentForm: paymentForm[0],
          ...data.extendedProps.data,
        }
      : {
          subject: null,
          room:
            filterData.type === "room"
              ? rooms.find((i) => i.id === filterData.value.id)
              : null,
          group:
            filterData.type === "group"
              ? groups.filter((i) => i.id === filterData.value.id)
              : [],
          teacher:
            filterData.type === "teacher"
              ? teachers.find((i) => i.id === filterData.value.id)
              : null,
          absence: false,
          recurrent: true,
          absenceReason: absency_reasons[0],
          absenceEffect: absency_effects[0],
          paymentForm: paymentForm[0],
        }
  );

  const onMultiSelection = (action, type) => {
    switch (action.action) {
      case "clear":
        setEventData({
          ...eventData,
          [type]: [],
        });
        break;
      case "remove-value":
        setEventData({
          ...eventData,
          [type]: eventData[type].filter(
            (i) => i.attendeeId.toString() !== action.removedValue.id.toString()
          ),
        });
        break;

      case "select-option":
        setEventData({
          ...eventData,
          [type]: eventData[type].concat([action.option]),
        });
        break;
      default:
        break;
    }
  };

  const onOnlySelection = (value, type) => {
    setEventData({
      ...eventData,
      [type]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMounted = useIsMounted();

  const auth = useAuth();

  const user = auth.user();
  const isTeacher = user.scope.includes("qpay:teacher");

  const validate = useCallback(() => {
    if (!eventData.teacher?.employeeId) {
      setError("Pole Nauczyciel jest wymagane");
      return false;
    }
    if (!eventData.subject?.subjectId) {
      setError("Pole Przedmiot jest wymagane");
      return false;
    } else {
      setError(null);
      return true;
    }
  }, [eventData.subject?.subjectId, eventData.teacher?.employeeId]);

  useEffect(() => {
    validate();
    return () => {};
  }, [eventData.subject, eventData.teacher?.employeeId, validate]);

  const saveChanges = () => {
    if (validate()) {
      setLoading(true);
      const createEventData = {
        subjectId: eventData.subject?.subjectId,
        locationId: eventData.room?.locationId,
        startTime: moment(data.start).format('YYYY-MM-DDTHH:mm:ss'),
        endTime: moment(data.end).format('YYYY-MM-DDTHH:mm:ss'),
        teacherId: eventData.teacher?.employeeId,
        attendees: eventData.group.map((a) => a.attendeeId),
        recurrent: eventData.recurrent,

        substituteTeacherId:
          eventData.absenceEffect === "Zastepstwo"
            ? eventData.substituteTeacher?.employeeId
            : null,
        absence: eventData.absence,
        ...(eventData.absence && {
          absence: eventData.absence,
          paymentForm: eventData.paymentForm,
          reasonForAbsence: eventData.absenceReason,
          absenceOutcome: eventData.absenceEffect,
        }),
      };

      const Instance = isTeacher ? TeacherEvent : Event;
      if (data.id !== "") {
        Instance.put(data.id, createEventData)
          .then((res) => {
            if (!res.success) {
              console.log(res.messages);
              alert(t("global.errors.update_event_error"));
            }
            setLoading(false);
            onSave();
          })
          .catch((err) => {
            console.log("axios err=", err);
            alert(t("global.errors.network_event_error"));
            if (isMounted()) setLoading(false);
          });
      } else {
        Instance.post(createEventData)
          .then((res) => {
            if (!res.success) {
              console.log(res.messages);
              alert(t("global.errors.create_event_error"));
            }
            setLoading(false);
            onSave();
          })
          .catch((err) => {
            console.log("axios err=", err);
            alert(t("global.errors.network_event_error"));
            if (isMounted()) setLoading(false);
          });
      }
    }
  };

  const deleteEvent = () => {
    setLoading(true);
    const Instance = isTeacher ? TeacherEvent : Event;

    Instance.delete(data.id)
      .then((res) => {
        setLoading(false);
        if (!res.success) {
          console.log(res.messages);
          alert(t("global.errors.delete_event_error"));
        }
        onSave();
      })
      .catch((err) => {
        console.log("axios err=", err);
        alert(t("global.errors.network_event_error"));
        if (isMounted()) setLoading(false);
      });
  };

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
        <Flex
          width={1}
          height={"100%"}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} mr={3} ml={3}>
              Data:{" "}
              <Button variation="link" color={"primary.dark"}>
                <Text bold fontSize={3}>
                  {data?.start?.toLocaleDateString()}
                </Text>
              </Button>
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Text bold fontSize={3}>
              Czas:{" "}
              <Button variation="link" color={"primary.dark"}>
                <Text bold fontSize={3}>
                  {data?.start?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Text>
              </Button>
              {" - "}
              <Button variation="link" color={"primary.dark"}>
                <Text bold fontSize={3}>
                  {data?.end?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Text>
              </Button>
            </Text>
          </Box>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Miejsce:
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Can
              giveBoolean={true}
              perform={"event:changeLocation"}
              ret={(valid) => (
                <SearchBar
                  isDisabled={!valid}
                  value={rooms.filter(
                    ({ id }) =>
                      eventData?.room?.locationId?.toString() === id.toString()
                  )}
                  onSelection={(value) => onOnlySelection(value, "room")}
                  data={rooms}
                  placeholder={"Miejsce..."}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Nauczyciel:
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Can
              giveBoolean={true}
              perform={"event:changeTeacher"}
              ret={(valid) => (
                <SearchBar
                  isDisabled={!valid}
                  value={
                    eventData.teacher
                      ? Object.assign({}, eventData?.teacher, {
                          id: eventData?.teacher?.employeeId,
                        })
                      : null
                  }
                  onSelection={(value) => onOnlySelection(value, "teacher")}
                  data={teachers.filter((i) => i.isActive)}
                  placeholder={"Nauczyciel..."}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Grupa:
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Can
              giveBoolean={true}
              perform={"event:changeGroup"}
              ret={(valid) => (
                <MultiSelect
                  isDisabled={!valid}
                  value={groups.filter(
                    ({ id }) =>
                      eventData?.group?.findIndex(
                        (item) => item?.attendeeId?.toString() === id.toString()
                      ) >= 0
                  )}
                  onSelection={(v, action) => onMultiSelection(action, "group")}
                  data={groups}
                  placeholder={"Grupa..."}
                  isMulti
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Przedmiot:
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Can
              giveBoolean={true}
              perform={"event:changeSubject"}
              ret={(valid) => (
                <SearchBar
                  isDisabled={!valid}
                  value={subjects.filter(
                    ({ id }) =>
                      eventData?.subject?.subjectId?.toString() ===
                      id.toString()
                  )}
                  onSelection={(value) => onOnlySelection(value, "subject")}
                  data={subjects}
                  placeholder={"Przedmiot..."}
                />
              )}
            />
          </Box>
        </Flex>
        {error ? (
          <Flex
            width={1}
            flexDirection={"row"}
            p={2}
            justifyContent={"space-around"}
            alignItems="center"
          >
            <Text bold color={"error.base"}>
              {error}
            </Text>
          </Flex>
        ) : null}
        <Can
          perform={"event:viewRepeat"}
          yes={() => (
            <Flex
              width={1}
              flexDirection={"row"}
              p={2}
              pl={3}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box>
                <Label fontSize={1}>
                  <Checkbox
                    id="one"
                    checked={eventData.recurrent}
                    onChange={() =>
                      setEventData({
                        ...eventData,
                        recurrent: !eventData.recurrent,
                      })
                    }
                  />{" "}
                  Powtarzanie
                </Label>
              </Box>
            </Flex>
          )}
        />

        <Divider width={0.95} mx={"auto"} />
        <Flex
          width={1}
          flexDirection={["column", "column", "column", "column", "row"]}
          p={2}
          px={3}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Label fontSize={1} nowrap>
            <Checkbox
              id="absence"
              checked={!!eventData.absence}
              onChange={() =>
                setEventData({ ...eventData, absence: !eventData.absence })
              }
            />{" "}
            Nieobecność
          </Label>
        </Flex>
        {eventData.absence && (
          <Flex flexDirection="column">
            <Flex
              width={1}
              p={2}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box width={1 / 2}>
                <Text bold fontSize={2} ml={3}>
                  Powód nieobecności:{" "}
                </Text>
              </Box>
              <Can
                giveBoolean={true}
                perform={"event:changeAbsenceReason"}
                ret={(valid) => (
                  <Box width={1 / 2}>
                    <StyledSelect
                      id="absenceReason"
                      name="absenceReason"
                      disabled={!valid}
                      value={eventData?.absenceReason || absency_reasons[0]}
                      onChange={(event) => {
                        setEventData({
                          ...eventData,
                          absenceReason: event.target.value,
                        });
                      }}
                    >
                      {absency_reasons.map((i) => (
                        <option value={i} key={i}>
                          {t("plan.ReasonForAbsence." + i)}
                        </option>
                      ))}
                    </StyledSelect>
                  </Box>
                )}
              />
            </Flex>
            <Flex
              width={1}
              p={2}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box width={1 / 2}>
                <Text bold fontSize={2} ml={3}>
                  Skutek nieobecności:{" "}
                </Text>
              </Box>
              <Can
                giveBoolean={true}
                perform={"event:changeAbsenceEffect"}
                ret={(valid) => (
                  <Box width={1 / 2}>
                    <StyledSelect
                      id="absenceEffect"
                      name="absenceEffect"
                      disabled={!valid}
                      value={eventData?.absenceEffect || absency_effects[0]}
                      onChange={(event) => {
                        setEventData({
                          ...eventData,
                          absenceEffect: event.target.value,
                        });
                      }}
                    >
                      {absency_effects.map((i) => (
                        <option value={i} key={i}>
                          {t("plan.AbsenceOutcome." + i)}
                        </option>
                      ))}
                    </StyledSelect>
                  </Box>
                )}
              />
            </Flex>
            <Can
              perform={"event:changePaymentForm"}
              yes={() => (
                <Flex
                  width={1}
                  p={2}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box width={1 / 2}>
                    <Text bold fontSize={2} ml={3}>
                      Forma zastępstwa:{" "}
                    </Text>
                  </Box>
                  <Box width={1 / 2}>
                    <StyledSelect
                      id="paymentForm"
                      name="paymentForm"
                      value={eventData?.paymentForm || paymentForm[0]}
                      onChange={(event) => {
                        setEventData({
                          ...eventData,
                          paymentForm: event.target.value,
                        });
                      }}
                    >
                      {paymentForm.map((i) => (
                        <option value={i} key={i}>
                          {t("plan.PaymentForm." + i)}
                        </option>
                      ))}
                    </StyledSelect>
                  </Box>
                </Flex>
              )}
            />
            {eventData?.absenceEffect === "Zastepstwo" ? (
              <Flex
                width={1}
                flexDirection={"row"}
                p={2}
                justifyContent={"space-around"}
                alignItems="center"
              >
                <Box width={1 / 2}>
                  <Text bold fontSize={2} ml={3}>
                    Zastępca:
                  </Text>
                </Box>
                <Can
                  giveBoolean={true}
                  perform={"event:changeSubstituteTeacher"}
                  ret={(valid) => (
                    <Box width={1 / 2} title={valid ? null : "Brak uprawnień"}>
                      <SearchBar
                        isDisabled={!valid}
                        value={
                          eventData.substituteTeacher
                            ? Object.assign({}, eventData?.substituteTeacher, {
                                id: eventData?.substituteTeacher?.employeeId,
                              })
                            : null
                        }
                        onSelection={(value) =>
                          onOnlySelection(value, "substituteTeacher")
                        }
                        data={teachers}
                        placeholder={"Nauczyciel..."}
                      />
                    </Box>
                  )}
                />
              </Flex>
            ) : null}
          </Flex>
        )}
        <Flex
          width={1}
          flexDirection={"row"}
          justifyContent="space-around"
          mt={1}
          mb={1}
        >
          <Button color={"secondary"} variation={"outline"} onClick={close}>
            Anuluj
          </Button>
          <Can
            perform={"event:delete"}
            yes={() => (
              <Button
                color={"ternary"}
                onClick={deleteEvent}
                disabled={loading}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  pt={loading ? 1 : 0}
                >
                  {loading ? <PulseLoader size={10} color={"white"} /> : `Usuń`}
                </Flex>
              </Button>
            )}
          />
          <Button
            color={"secondary"}
            onClick={saveChanges}
            disabled={loading || !!error}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              pt={loading ? 1 : 0}
            >
              {loading ? <PulseLoader size={10} color={"white"} /> : `Zapisz`}
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

const StyledSelect = styled(Select)`
  padding: 5px 10px;
`;

export default EditLesson;
