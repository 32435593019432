import React from "react";

import {
  Box,
  Text,
  getPaletteColor,
  Flex,
  Card,
  Button,
  Divider,
} from "pcln-design-system";
import styled from "styled-components";
import tabConfig from "./tabConfig";
import Icon from "components/Icon";
import { matchPath, useHistory, useLocation } from "react-router";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { CHANGE_PASSWORD, LOGIN } from "navigation/CONSTANTS";
import Popup from "reactjs-popup";
import { Can } from "components";

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 8px;
`;

const CardTab = styled(Box)`
  background: ${(props) =>
    props.active ? getPaletteColor("background.light") : "transparent"};
  box-shadow: ${(props) =>
    props.active ? "-2px -2px 6px rgba(0, 0, 0, 0.25)" : "unset"};
  clip-path: inset(-8px -8px 0px -8px);
  border-radius: 5px 5px 0px 0px;
  min-width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1px;

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.active
        ? getPaletteColor("background.light")
        : getPaletteColor("background.lightest")};
    box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.25);
  }
`;

const StyledText = styled(Text)`
  transition: color 0.25s;
  color: ${(props) =>
    props.active
      ? getPaletteColor("border.dark")
      : getPaletteColor("text.lightest")};

  ${CardTab}:hover & {
    color: ${(props) =>
      props.active
        ? getPaletteColor("border.dark")
        : getPaletteColor("text.light")};
  }
`;

const AIcon = styled(Icon)`
  transition: fill 0.25s;
  fill: ${(props) =>
    props.active
      ? getPaletteColor("border.dark")
      : getPaletteColor("text.lightest")};

  ${CardTab}:hover & {
    fill: ${(props) =>
      props.active
        ? getPaletteColor("border.dark")
        : getPaletteColor("text.light")};
  }
`;

const Tab = ({ active, icon, text, index, path, onClick, ...props }) => {
  return (
    <CardTab active={active} px={"12px"} py={1} onClick={onClick}>
      <AIcon name={icon} width="25px" height="25px" active={active} />
      <StyledText active={active} bold color={"text.light"} pl={2}>
        {text}
      </StyledText>
    </CardTab>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100px;
  background: ${getPaletteColor("gradient.base")};

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 10px 10px 0px;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-left: 20px;
`;

const LogoDiv = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 1.5px solid ${(props) => getPaletteColor("background.light")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;

// const LogoImg = styled.svg`
//   width: 50px;
//   height: 50px;
//   border-radius: 5%;
//   cursor: pointer;
//   fill: red;
// `;

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 5px;
    transform: translate(0px, 4px);
  }
  &-arrow {
    color: ${getPaletteColor("background.lightest")};
  }
`;

const NoOutlineButton = styled(Button)`
  &:focus {
    outline: none;
  }
`;

const TopBar = () => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const logoutHandler = () => {
    auth.signout().then((res) => {
      history.replace(LOGIN);
    });
  };

  return (
    <Container>
      <Flex
        flexDirection="row"
        width={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Logo src={"/QVX-logo.svg"} alt="logo" />
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pr={3}
        >
          {/* <Icon
            name="User_1"
            width="25px"
            height="25px"
            color="background.lightest"
          /> */}
          {/*
          <Icon
            name="Alarm"
            width="25px"
            height="25px"
            color="background.lightest"
            margin="0px 20px"
          /> */}
          <Text mr={2} bold color={"text.lightest"}>
            {auth.user().name}
          </Text>
          <StyledPopup
            trigger={
              <LogoDiv>
                {/* <LogoImg src={"/abstract-user-flat-1.svg"} alt="avatar" /> */}
                <Icon
                  name="User_1"
                  color="background.lightest"
                  width="33px"
                  height="33px"
                />
              </LogoDiv>
            }
            position={["bottom right", "bottom left", "top right"]}
            on="hover"
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            keepTooltipInside={".app"}
            arrow={true}
          >
            <Card
              boxShadowSize={"md"}
              borderRadius={2}
              borderWidth={1}
              color={"background.lightest"}
            >
              <Flex p={3} flexDirection={"column"}>
                <NoOutlineButton
                  variation="link"
                  my={1}
                  onClick={() => {
                    history.push(CHANGE_PASSWORD);
                  }}
                >
                  <Text bold>Zmiana hasła</Text>
                </NoOutlineButton>
                <Divider />
                <NoOutlineButton
                  variation="link"
                  my={1}
                  onClick={logoutHandler}
                >
                  <Text bold>Wyloguj</Text>
                </NoOutlineButton>
              </Flex>
            </Card>
          </StyledPopup>
        </Flex>
      </Flex>
      <CardContainer>
        {tabConfig.map((item, index) => {
          const active = matchPath(location.pathname, {
            path: `${item.path}`,
            exact: true,
          });
          return (
            <Can
              key={index}
              perform={item.permission}
              yes={() => (
                <Tab
                  {...item}
                  active={active}
                  onClick={() => history.push(item.path)}
                />
              )}
            />
          );
        })}
      </CardContainer>
    </Container>
  );
};

export default TopBar;
