import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  getPaletteColor,
  Input,
  Label,
  Text,
} from "pcln-design-system";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PLAN } from "navigation/CONSTANTS";
import { PulseLoader } from "react-spinners";
import { changePassword } from "services";
import { mapError } from "utils/errorMapping";

const ChangePassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  const changePasswordHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    changePassword({
      newPassword: new_password,
      newPasswordConfirmation: confirm_password,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          history.push(PLAN);
        } else {
        }
      })
      .catch((err) => {
        if (err.response.data.success === false)
          setError("Nie można zmienić hasła - hasło jest zbyt proste");
        else {
          setError(mapError(err.response));
        }
        setIsLoading(false);
      });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (new_password.length < 8) {
      setError("Hasło za krótkie");
    } else {
      if (new_password !== confirm_password) {
        setError("Hasła nie zgadzają się");
      } else {
        setError(null);
      }
    }
    return () => {};
  }, [new_password, confirm_password]);

  return (
    <Bg
      width={1}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      color={"background.light"}
    >
      <Flex
        width={1}
        justifyContent={"center"}
        my={3}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Card
          width={"450px"}
          color={"primary.darkest"}
          p={3}
          borderWidth={0}
          borderRadius={1}
          boxShadowSize={"lg"}
        >
          <form onSubmit={changePasswordHandler}>
            <Flex
              width={1}
              justifyContent={"space-around"}
              alignItems={"center"}
              mb={2}
            >
              <Logo src={"./QVX-logo.svg"} alt="logo" />
              <Text bold fontSize={4} color={"text.lightest"}>
                Menedżer
                <br /> planu zajęć
              </Text>
            </Flex>
            <Flex
              width={1}
              justifyContent={"space-around"}
              alignItems={"center"}
              my={1}
            >
              <Text fontSize={3} bold>
                Zmiana hasła
              </Text>
            </Flex>
            <Label htmlFor="new_password" color={"text.lightest"} fontSize={1}>
              Nowe hasło:
            </Label>
            <Input
              id="new_password"
              name="new_password"
              type="password"
              value={new_password}
              onChange={(event) => setNew_password(event.target.value)}
              mb={1}
            />
            <Label
              htmlFor="confirm_password"
              color={"text.lightest"}
              fontSize={1}
            >
              Potwierdź hasło:
            </Label>
            <Input
              id="confirm_password"
              name="confirm_password"
              type="password"
              value={confirm_password}
              onChange={(event) => setConfirm_password(event.target.value)}
            />
            {error ? (
              <Text bold color={"error.base"}>
                {error}
              </Text>
            ) : null}
            <Flex
              mt={3}
              width={1}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <LinkButton variation={"link"} type={"button"} onClick={goBack}>
                Powrót
              </LinkButton>
              <LoginButton type={"submit"} disabled={isLoading}>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  pt={isLoading ? 1 : 0}
                >
                  {isLoading ? (
                    <PulseLoader size={10} color={"white"} />
                  ) : (
                    `Zmień hasło`
                  )}
                </Flex>
              </LoginButton>
            </Flex>
          </form>
        </Card>
      </Flex>
    </Bg>
  );
};

const Bg = styled(Flex)`
  background: url("/bg.jpg") center center / cover no-repeat
    ${(props) => getPaletteColor(props.color)};
`;

const LinkButton = styled(Button)`
  color: ${getPaletteColor("text.lightest")};
  &:hover {
    color: ${getPaletteColor("text.lightest")};
  }
`;

const LoginButton = styled(Button)`
  background-color: ${getPaletteColor("primary.dark")};
  &:hover {
    background: ${getPaletteColor("primary.base")};
  }
  &:disabled {
    background: ${getPaletteColor("text.light")};
  }
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
  margin-left: 20px;
`;

export default ChangePassword;
