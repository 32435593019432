import { useState, useRef } from "react";

import plLocale from "@fullcalendar/core/locales/pl";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Flex, Text } from "pcln-design-system";
import moment from "moment";
import styled from "styled-components";
import Popup from "reactjs-popup";

const EMPTY_SLOT_COLOR = "#e4e4e4";

const StyledPopup = styled(Popup)`
  &-content {
    background: ${(props) => props.backgroundColor};
    border-radius: 5px;
    filter: drop-shadow(0px 0px 4px rgb(0, 0, 0, 0.4));
    padding: 4px;
    transform: translate(0px, 4px);
  }
  &-arrow {
    color: ${(props) => props.backgroundColor};
  }
`;

const usePlan = ({ editEvent = () => {}, initialDate, fetchedEvents }) => {
  const [title, setTitle] = useState("");
  const calendar = useRef(null);

  const [selectedDate, setSelectedDate] = useState(
    initialDate !== null
      ? moment(Number(initialDate)).weekday(1).startOf("day").valueOf()
      : moment().weekday(1).startOf("day").valueOf()
  );

  const renderEventContent = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;

    const text = data?.eventId ? (
      <>
        {data.subject?.name} <br />
        {data.group
          ?.map((i) => i?.name)
          .slice(0, 3)
          .concat(data.group.length > 3 ? "..." : "")
          .join(" ")}{" "}
        {data.room?.name}
      </>
    ) : null;

    return (
      <Flex
        height={"100%"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{
          cursor: "pointer",
        }}
      >
        <StyledPopup
          backgroundColor={eventInfo.event.backgroundColor}
          trigger={
            <Flex flexDirection={"column"}>
              {!data?.eventId ? (
                <Text
                  color={data?.eventId ? "text.lightest" : "text.lighter"}
                  fontSize={0}
                >
                  {`${moment(eventInfo.event.start).format("HH:mm")} -
                ${moment(eventInfo.event.end).format("HH:mm")}`}
                </Text>
              ) : null}
              {data?.eventId ? (
                <Text color={"text.lightest"} bold>
                  {text}
                </Text>
              ) : null}
            </Flex>
          }
          position={["bottom right", "bottom left", "top right"]}
          on="hover"
          closeOnDocumentClick
          mouseLeaveDelay={100}
          mouseEnterDelay={0}
          keepTooltipInside={".app"}
          disabled={!data?.eventId}
          arrow={true}
        >
          <Flex flexDirection={"column"} p={2}>
            {!data?.eventId ? (
              <Text
                color={data?.eventId ? "text.lightest" : "text.lighter"}
                fontSize={0}
              >
                {`${moment(eventInfo.event.start).format("HH:mm")} -
                ${moment(eventInfo.event.end).format("HH:mm")}`}
              </Text>
            ) : null}
            {data?.eventId ? (
              <Text fontSize={1} color={"text.lightest"} bold>
                {data.subject?.name} {data.group.length ? <br /> : null}
                {data.group?.map((i) => i?.name).join(", ")}
                {data.room?.name ? <br /> : null}
                {data.room?.name}
              </Text>
            ) : null}
          </Flex>
        </StyledPopup>
      </Flex>
    );
  };

  const changeDay = (arg) => {
    const calendarApi = calendar.current.getApi();
    if (typeof arg === "number") {
      switch (arg) {
        case -1:
          if (
            moment(calendarApi.getDate()).weekday(1).startOf("day") >
            calendarApi.currentDataManager.data.calendarOptions.validRange.start
          )
            calendarApi.prev();

          break;
        case 0:
          calendarApi.today();
          break;
        case 1:
          if (
            moment(calendarApi.getDate())
              .weekday(1)
              .startOf("day")
              .add(7, "days") <
            calendarApi.currentDataManager.data.calendarOptions.validRange.end
          )
            calendarApi.next();
          break;
        default:
          break;
      }
    } else {
      const startOfLastYear = moment().startOf("year").subtract(1, "year");
      const endOfNextYear = moment().endOf("year").add(1, "year");
      let actual = moment.max(startOfLastYear, moment(arg));
      actual = moment.min(endOfNextYear, actual);

      calendarApi.gotoDate(actual.valueOf());
    }
    setTitle(calendarApi.view.title);
    setSelectedDate(
      moment(calendarApi.getDate()).weekday(1).startOf("day").valueOf()
    );
  };

  const handleEventClick = (clickInfo) => {
    editEvent(clickInfo.event);
  };

  const config = {
    initialDate: selectedDate,
    locale: plLocale,
    ref: calendar,
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: "timeGridWeek",
    headerToolbar: false,
    weekends: false,
    allDaySlot: false,
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },

    viewDidMount: (view) => {
      setTitle(view.view.title);
    },

    slotDuration: "00:30:00",
    slotMinTime: "06:00:00",
    slotMaxTime: "20:00:00",
    slotLabelFormat: {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: false,
      meridiem: "short",
    },
    slotLabelInterval: "01:00:00",

    eventSources: [fetchedEvents],

    eventContent: renderEventContent,
    eventBackgroundColor: EMPTY_SLOT_COLOR,
    eventBorderColor: EMPTY_SLOT_COLOR,
    eventClick: handleEventClick,

    validRange: {
      start: moment().startOf("year").subtract(1, "year"),
      end: moment().endOf("year").add(1, "year"),
    },
  };

  return {
    config,
    title,
    changeDay,
    selectedDate,
  };
};

export { usePlan };
