import { useState } from "react";
import { AG_GRID_LOCALE_PL } from "i18n/ag-grid/locale.pl";
import moment from "moment";
import { PLAN } from "navigation/CONSTANTS";
import { useTranslation } from "react-i18next";

const Link = (cell) => {
  if (cell.data.employeeId === null) {
    return (
      <a
        href="/#"
        onClick={(e) => {
          e.preventDefault();
          cell.context.deleteEvent(cell.data);
          return false;
        }}
      >
        Usuń
      </a>
    );
  }

  if (cell.data.conflictType === "AttendeeParallel") {
    return (
      <a
        href={`${PLAN}?type=group&v=${
          cell.data.attendees[0].attendeeId
        }&date=${moment(cell.data.startTime).valueOf()}`}
        target="_blank"
        rel="noreferrer"
      >
        Przejdź
      </a>
    );
  } else
    return (
      <a
        href={`${PLAN}?type=teacher&v=${cell.data.employeeId}&date=${moment(
          cell.data.startTime
        ).valueOf()}`}
        target="_blank"
        rel="noreferrer"
      >
        Przejdź
      </a>
    );
};

const useExceptions = ({ rowData, onDeleteEvent = () => {} }) => {
  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const { t } = useTranslation("translation", { keyPrefix: "exceptions" });

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const gridConfig = {
    rowData: rowData,
    onGridReady: onGridReady,
    frameworkComponents: { linkCellRenderer: Link },
    context: { deleteEvent: onDeleteEvent },
    localeText: AG_GRID_LOCALE_PL,
    stopEditingWhenCellsLoseFocus: true,
    suppressDragLeaveHidesColumns: true,
  };

  const columnDefinitions = [
    {
      field: `eventId`,
      headerName: "Id",
      sortable: false,
      filter: true,
      resizable: false,
      flex: 0.2,
      checkboxSelection: false,

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },
    {
      field: "employeeName",
      headerName: "Nauczyciel",
      sortable: true,
      filter: true,
      resizable: false,
      flex: 0.8,
      checkboxSelection: false,
      editable: false,

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },
    {
      field: "conflictType",
      headerName: "Typ",
      valueFormatter: (params) => {
        return t(`conflictTypes.${params.value}`);
      },
      sortable: true,
      filter: true,
      resizable: false,
      flex: 0.8,
      checkboxSelection: false,
      editable: false,

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },
    {
      field: "startTime",
      valueFormatter: (params) => {
        return moment(params.value).format("YYYY-MM-DD  HH:mm");
      },
      headerName: "Data",
      sortable: true,
      filter: true,
      resizable: false,
      flex: 0.8,
      checkboxSelection: false,
      editable: false,

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },
    {
      field: "name",
      headerName: "Akcje",
      sortable: true,
      filter: true,
      resizable: false,
      flex: 0.2,
      checkboxSelection: false,
      editable: false,

      cellRenderer: "linkCellRenderer",

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },
  ];

  return {
    gridConfig,
    columnDefinitions,
  };
};
export { useExceptions };
