import { useState } from "react";
import { AG_GRID_LOCALE_PL } from "i18n/ag-grid/locale.pl";
import moment from "moment";

const Link = (props) => {
  return (
    <a href={props.data.reportUrl} target="_blank" rel="noreferrer">
      Przejdź do raportu
    </a>
  );
};

const Remarks = (cell) => {
  return (
    <a
      href="/#"
      onClick={(e) => {
        e.preventDefault();
        cell.context.viewRemarks({
          ...cell.data,
          date: moment(cell.data.month, "YYYY.MM").toDate(),
        });
        return false;
      }}
      target="_blank"
      rel="noreferrer"
    >
      Uwagi
    </a>
  );
};

const useReports = ({
  rowData,
  onDeleteEvent = () => {},
  onViewRemarks = () => {},
  date,
}) => {
  const [, setGridApi] = useState(null);
  const [, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const gridConfig = {
    rowData: rowData,
    onGridReady: onGridReady,
    frameworkComponents: {
      linkCellRenderer: Link,
      remarksCellRenderer: Remarks,
    },
    context: { deleteEvent: onDeleteEvent, viewRemarks: onViewRemarks },
    localeText: AG_GRID_LOCALE_PL,
    rowSelection: "single",
    rowMultiSelectWithClick: true,
    stopEditingWhenCellsLoseFocus: true,
    suppressDragLeaveHidesColumns: true,
    overlayNoRowsTemplate: "Nie znaleziono raportów",
  };

  const columnDefinitions = [
    {
      field: `employeeId`,
      headerName: "Id",
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.2,
      checkboxSelection: false,
    },

    {
      field: "employeeName",
      headerName: "Nauczyciel",
      sortable: true,
      filter: true,
      resizable: false,
      flex: 0.8,
      editable: false,

      filterParams: {
        suppressAndOrCondition: true,
        closeOnApply: true,
        buttons: ["apply", "clear", "reset", "cancel"],
      },
    },

    {
      field: "hoursWorked",
      headerName: "Przepracowane godziny",
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.5,
      checkboxSelection: false,
      editable: false,
    },

    {
      field: "month",
      headerName: "Miesiąc",
      sortable: true,
      filter: false,
      resizable: false,
      flex: 0.5,
      checkboxSelection: false,
      editable: false,
    },

    {
      headerName: "Uwagi",
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.5,
      checkboxSelection: false,
      editable: false,

      cellRenderer: "remarksCellRenderer",
    },
    {
      field: "name",
      headerName: "Akcje",
      sortable: false,
      filter: false,
      resizable: false,
      flex: 0.5,
      checkboxSelection: false,
      editable: false,

      cellRenderer: "linkCellRenderer",
      cellRendererParams: { date: date },
    },
  ];

  return {
    gridConfig,
    columnDefinitions,
  };
};
export { useReports };
