import React from "react";

import { Flex, Text } from "pcln-design-system";
import { colors } from "styles/events";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledItem = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: ${(props) => props.color};
`;

const Legend = () => {
  const { t } = useTranslation("translation", { keyPrefix: "global" });
  return (
    <Flex flexDirection="row" alignItems="center">
      {Object.keys(colors).map((it, ind) => (
        <Flex flexDirection="row" alignItems="center" mr={3} key={ind}>
          <StyledItem color={colors[it]} />
          <Text ml={1} bold fontSize={1} color={"text.light"}>
            {t(`colors.` + it)}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Legend;
