import React, { useState, forwardRef } from 'react'

import { ThemedButton } from '.'

import Icon from 'components/Icon'

import 'react-datepicker/dist/react-datepicker.css'
import './customDatePicker.scss'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

import pl from 'date-fns/locale/pl'
import { Flex, Text } from 'pcln-design-system'
import moment from 'moment'
registerLocale('pl', pl)
setDefaultLocale('pl')

const DatePickerButton = ({
  onSelect,
  disabled,
  showDate = false,
  format = 'DD/MM/YYYY',
  value = null,
  isCleared = false,
  ...rest
}) => {
  const [date, setDate] = useState(value ? value : new Date())

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <ThemedButton
      variation={'plain'}
      title={'Wybór daty'}
      p={0}
      pt={2}
      px={3}
      pb={1}
      disabled={disabled}
      onClick={onClick}
      innerRef={ref}
    >
      <Flex mb={1}>
        <Icon name="Calendar" width="25px" height="25px" color={'primary.dark'} />
        {showDate ? <Text ml={2}>{isCleared ? '???' : moment(date).format(format)}</Text> : null}
      </Flex>
    </ThemedButton>
  ))

  return (
    <DatePicker
      wrapperClassName={'react-datepicker-wrapper2'}
      selected={date}
      onChange={(date) => {
        setDate(date)
        onSelect(date)
      }}
      customInput={<ExampleCustomInput />}
      {...rest}
    />
  )
}

const MonthPickerButton = ({ onSelect, disabled }) => {
  const [date, setDate] = useState(new Date())

  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <ThemedButton
      variation={'plain'}
      title={'Wybór daty'}
      p={0}
      pt={2}
      px={3}
      pb={1}
      disabled={disabled}
      // p="auto"
      onClick={onClick}
      innerRef={ref}
    >
      <Icon name="Calendar" width="25px" height="25px" color={'primary.dark'} />
      <Text>{`${value}`}</Text>
    </ThemedButton>
  ))

  return (
    <DatePicker
      selected={date}
      wrapperClassName={'react-datepicker-wrapper2'}
      dateFormat="LLLL / yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      onChange={(date) => {
        setDate(date)
        onSelect(date)
      }}
      customInput={<CustomButton />}
    />
  )
}

export { DatePickerButton, MonthPickerButton }
