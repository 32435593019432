import React from "react";
// Handle console logs
import "utils/dropConsole";

// ROUTER
import { Router } from "react-router-dom";
import history from "navigation";
import { RouterConfig } from "navigation/RouterConfig";
// MUI Theme
import { ThemeProvider } from "pcln-design-system";
import { theme } from "./styles/theme";
// import { ThemeProvider, Button, createMuiTheme } from "@material-ui/core";
// import { ThemeSwitch } from "components/ThemeSwitch";
// import { dark, light } from "styles/muiTheme";
import "./App.css";
import { ProvideAuth } from "navigation/Auth/ProvideAuth";
// Redux
import { Provider } from "react-redux";
import { store } from "redux/store";

//drag and drop
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

function App() {
  // const [darkState, setDarkState] = useState(false);
  // const handleThemeChange = () => {
  //   setDarkState(!darkState);
  //   console.log("theme=", darkState ? "dark" : "light");
  // };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="app">
          <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
              {/* <ThemeSwitch
              darkState={darkState}
              handleThemeChange={handleThemeChange}
            />  */}
              <ProvideAuth>
                <Router
                  history={history}
                  basename={process.env.NODE_ENV === "production" ? "/" : "/"}
                >
                  <RouterConfig />
                </Router>
              </ProvideAuth>
            </Provider>
          </DndProvider>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
