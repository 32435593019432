import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  Label,
  Text,
} from "pcln-design-system";
import { useIsMounted, useQueryParams } from "utils";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";

import styled from "styled-components";
import Popup from "reactjs-popup";
import ErrorPopup from "../ErrorPopup";

import ControlledSearchBar from "pages/Plan/components/SearchBar";
import { DatePickerButton, Icon } from "components";
import { useEditContract } from "./useEditContract";
import { Employee, EmployeeSubject } from "services";
import { CONTRACT } from "navigation/CONSTANTS";
import { useHistory } from "react-router";
import moment from "moment";

const getNextAugust = () => {
  const monthINeed = 7; // for August
  const today = moment().month();
  // if we haven't yet passed the month that I need:
  if (today <= monthINeed) {
    // then just give me this year's instance of that day
    return moment().month(monthINeed).endOf("month");
  } else {
    // otherwise, give me *next year's* instance of that same day
    return moment().add(1, "year").month(monthINeed).endOf("month");
  }
};

const EditContract = () => {
  let query = useQueryParams();
  const { teachers } = useSelector((state) => state.constants);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [teacherData, setTeacherData] = useState({ id: query.get("v") });

  const { columnDefinitions, gridConfig, editedRows } = useEditContract({
    onCellDelete: (cell_data) => {},
    onNewCellDelete: (cell_data) => {
      const newRowData = rowData
        .slice()
        .filter((i) => i.some_id !== cell_data.some_id);
      setRowData(newRowData);
    },
  });

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const closeErrorPopup = () => {
    setOpenErrorPopup(false);
    setErrorArray([]);
  };

  const isMounted = useIsMounted();

  const default_contract = {
    name: teachers.find((i) => i.id.toString() === teacherData?.id)?.name,
    employeeId: teacherData?.id,
    // some_id: false,
    some_id:
      Math.max(0, ...rowData.map((o, i) => (o.some_id ? o.some_id : i))) + 1,
    isActive: false,
    validFrom: moment().startOf("month"),
    validTo: getNextAugust(),
    subject: { name: "" },
    contractHours: 0,
    hourlyRate: 0,
  };
  const history = useHistory();

  useEffect(() => {
    if (teacherData && teacherData.id !== null) {
      history.replace(
        `${CONTRACT}?v=${teacherData?.id}&date=${selectedDate.valueOf()}`
      );
    } else if (teacherData === null) {
      history.replace(`${CONTRACT}?date=${selectedDate.valueOf()}`);
    }
    return () => {};
  }, [selectedDate, teacherData, teacherData?.id, history]);

  const [dateSearch, setDateSearch] = useState(true);

  useEffect(
    () => {
      setLoading(true);
      editedRows.set([]);
      if (teacherData?.id) {
        Employee.getOne(teacherData?.id, {
          ...(dateSearch && {
            contractDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            includeRelevantContractsOnly: true,
          }),
        })
          .then((res) => {
            if (isMounted()) {
              if (teacherData?.id) {
                setRowData(
                  res.data.data.subjects.map((it, ind) => ({
                    ...it,
                    some_id: ind,
                    name: res.data.data.name,
                    employeeId: res.data.data.employeeId,
                  }))
                );
              } else {
                const data = res.data.data.results
                  .map((i) =>
                    i.subjects.map((it, ind) => ({
                      ...it,
                      name: i.name,
                      some_id: ind,
                      employeeId: i.employeeId,
                    }))
                  )
                  .flat();
                setRowData(data);
              }
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("axios err=", err);
            if (isMounted()) setLoading(false);
          });
      } else {
        Employee.get(null, {
          ...(dateSearch && {
            contractDate: selectedDate ? moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            includeRelevantContractsOnly: true,
          }),
        })
          .then((res) => {
            if (isMounted()) {
              if (teacherData?.id) {
                setRowData(
                  res.data.data.subjects.map((it, ind) => ({
                    ...it,
                    some_id: ind,
                    name: res.data.data.name,
                    employeeId: res.data.data.employeeId,
                  }))
                );
              } else {
                const data = res.data.data.results
                  .map((i) =>
                    i.subjects.map((it, ind) => ({
                      ...it,
                      name: i.name,
                      some_id: ind,
                      employeeId: i.employeeId,
                    }))
                  )
                  .flat();
                setRowData(data);
              }
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log("axios err=", err);
            if (isMounted()) setLoading(false);
          });
      }

      return () => {};
    },
    // eslint-disable-next-line
    [refresh, selectedDate, dateSearch, isMounted]
  );

  const [triggerSave, setTriggerSave] = useState(false);
  useEffect(
    () => {
      setLoading(true);
      Promise.allSettled(
        editedRows.get.map((row) => {
          if (row.data.id) {
            const data = {
              subjectId: row.data.subject.subjectId,
              validFrom: moment(row.data.validFrom).format('YYYY-MM-DDTHH:mm:ss'),
              validTo: moment(row.data.validTo).format('YYYY-MM-DDTHH:mm:ss'),
              hourlyRate: row.data.hourlyRate,
              contractHours: row.data.contractHours,
              isActive: row.data.isActive,
            };
            return EmployeeSubject.put(
              {
                employeeId: teacherData?.id
                  ? teacherData.id
                  : row.data.employeeId,
                subjectId: row.data.id,
              },
              data
            );
          } else {
            const data = {
              subjectId: row.data.subject.subjectId,
              validFrom: moment(row.data.validFrom).format('YYYY-MM-DDTHH:mm:ss'),
              validTo: moment(row.data.validTo).format('YYYY-MM-DDTHH:mm:ss'),
              hourlyRate: row.data.hourlyRate,
              contractHours: row.data.contractHours,
              isActive: row.data.isActive,
            };
            return EmployeeSubject.post(
              {
                employeeId: teacherData?.id,
              },
              data
            );
          }
        })
      ).then((responses) => {
        responses.forEach((res, index) => {
          if (res?.status === "rejected")
            setErrorArray([
              ...errorArray,
              {
                text: <b>{editedRows.get[index].data.name}</b>,
                rowIndex: editedRows.get[index].rowIndex,
                resData: res.reason.response.data,
              },
            ]);
        });

        if (responses.filter((r) => r.status === "rejected").length)
          setOpenErrorPopup(true);

        setLoading(false);
        setRefresh(!refresh);
      });

      return () => {};
    },
    // eslint-disable-next-line
    [triggerSave, isMounted]
  );

  const handleSaveButton = () => {
    setTriggerSave(!triggerSave);
  };

  return (
    <>
      <SCard
        color="background.lightest"
        boxShadowSize="md"
        borderWidth={0}
        borderRadius={5}
      >
        <Flex
          width={1}
          height={"100%"}
          flexDirection="column"
          className={"container"}
        >
          <Flex
            width={1}
            p={2}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Flex pl={2} mr={2} alignItems={"center"}>
              <Text mr={2} fontSize={3}>
                Nauczyciel:{" "}
              </Text>
              <ControlledSearchBar
                width={"300px"}
                isClearable={true}
                value={teachers.filter(
                  ({ id }) => id.toString() === teacherData?.id?.toString()
                )}
                data={teachers}
                placeholder={"Nauczyciel..."}
                onSelection={(data) => {
                  setTeacherData(data);
                  setRefresh(!refresh);
                }}
              />
              <Box ml={4}>
                <DatePickerButton
                  showDate
                  disabled={!dateSearch}
                  onSelect={(date) => {
                    setSelectedDate(date);
                  }}
                />
              </Box>
              <Label ml={2} fontSize={1}>
                <Checkbox
                  id="search-date"
                  checked={dateSearch}
                  onChange={(event) => setDateSearch(event.target.checked)}
                />
                Filtr daty
              </Label>
            </Flex>
            <Flex ml={"auto"} mr={3} alignItems={"center"}>
              <Button
                variation={"outline"}
                onClick={() => {
                  setRowData([...rowData, default_contract]);
                }}
                disabled={!teacherData?.id}
              >
                <Flex flexDirection="row">
                  <Icon
                    name="Plus"
                    width="20px"
                    height="20px"
                    color={"primary.base"}
                  />
                  <Text ml={2}> Utwórz nowy</Text>
                </Flex>
              </Button>
              <Button
                ml={2}
                variation={"fill"}
                color="secondary"
                disabled={!editedRows.get.length}
                onClick={() => {
                  handleSaveButton();
                }}
              >
                <Flex flexDirection="row">
                  {loading ? (
                    <PulseLoader size={10} color={"white"} />
                  ) : (
                    <>
                      <Icon
                        name="Diskette"
                        width="20px"
                        height="20px"
                        color={
                          !editedRows.get.length
                            ? "text.light"
                            : "primary.lightest"
                        }
                      />
                      <Text ml={2}>Zapisz</Text>
                    </>
                  )}
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
            {!loading ? (
              <AgGridReact rowData={rowData} {...gridConfig}>
                {columnDefinitions().map((col, index) => (
                  <AgGridColumn {...col} key={index} />
                ))}
              </AgGridReact>
            ) : (
              <Flex
                width={1}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PulseLoader color={"blue"} margin={10} />
              </Flex>
            )}
          </div>
        </Flex>
      </SCard>
      <StyledPopup
        open={openErrorPopup}
        closeOnDocumentClick
        onClose={closeErrorPopup}
      >
        {(close) => <ErrorPopup data={errorArray} close={close} />}
      </StyledPopup>
    </>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  /* min-height: calc(100vh - 100px - 40px); */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 50px 0px;
  }
  &-content {
    width: clamp(470px, 70vw, 650px);
  }
`;

export default EditContract;
