import React from "react";
import styled from "styled-components";
import * as icons from "assets/icons";
import { getPaletteColor } from "pcln-design-system";
import { margin } from "styled-system";

const BaseIcon = React.forwardRef(({ name, active, ...props }, ref) => {
  const isDev = process.env.NODE_ENV !== "production";

  const Component = icons[name];

  if (!Component) {
    if (isDev) {
      console.trace(
        name ? `icon ${name} does not exist` : "icon is missing name prop"
      );
    }
    return null;
  } else {
    return (
      <Component title={props.title} focusable={false} {...props} ref={ref} />
    );
  }
});
const Icon = styled(BaseIcon)`
  outline: none;
  ${margin}
  fill: ${(props) => props.color && getPaletteColor(props.color)};
`;

export default Icon;
