import React from "react";
import { TopBar } from "./";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const Bg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f4f6f8;
  padding: 20px;
  overflow-y: auto;
`;

const BaseLayout = ({ children }) => {
  return (
    <StyledWrapper>
      <TopBar />
      <Bg>{children}</Bg>
    </StyledWrapper>
  );
};

export default BaseLayout;
