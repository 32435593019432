import { login, claims, logout, teacher } from "services";
import useToken from "utils/useToken";

export function useProvideAuth() {
  const { setToken, token } = useToken();

  // signin method: It can either return a promise or execute a callback function.
  // You can prefer to keep this in userServices.js

  const signin = (formData) => {
    return new Promise((resolve, reject) => {
      try {
        // do db call or API endpoint axios call here and return the promise.
        login(formData)
          .then((res) => {
            getUser()
              .then((res) => {
                if (res.success) {
                  setToken(res.data);
                  resolve(res);
                }
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            setToken(null);
            reject(err);
          });
      } catch (error) {
        console.error("signin error!==", error);
        reject(error);
      }
    });
  };
  const signout = () => {
    return new Promise((resolve, reject) => {
      try {
        // do API endpoint axios call here and return the promise.
        logout()
          .then((res) => {})
          .catch((err) => {});
        setToken(null);
        resolve(true);
      } catch (error) {
        console.error("signout error!==", error);
        reject("signout error!");
      }
    });
  };

  const getUser = () => {
    return new Promise((resolve, reject) => {
      try {
        // do API endpoint axios call here and return the promise.
        claims()
          .then((res) => {
            teacher()
              .then((teach) => {
                if (teach.success) {
                  res.data.employeeId = teach.data.employeeId;
                  res.data.name = teach.data.name;
                  resolve(res);
                } else {
                  resolve(res);
                }
              })
              .catch((err) => {
                console.log("useProvideAuth > signin > teacher > err=", err);

                if (err.response.status === 401) {
                  resolve(res);
                } else {
                  reject(err);
                }
              });
          })
          .catch((err) => {
            console.log("useProvideAuth > signin > getUserDetails > err=", err);
            reject(err);
          });
      } catch (error) {
        console.error("signout error!==", error);
        reject(error);
      }
    });
  };

  return {
    signin,
    signout,
    user: token,
  };
}
