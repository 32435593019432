import {
  SET_CONSTANTS,
  SET_GROUPS,
  SET_ROOMS,
  SET_SUBJECTS,
  SET_TEACHERS,
} from "redux/actions/constantsActions";

const initialState = {
  teachers: [],
  groups: [],
  rooms: [],
  subjects: [],
  absency_reasons: [],
  absency_effects: [],
  substitution_forms: [],
};

export const constantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case SET_ROOMS:
      return {
        ...state,
        rooms: action.payload,
      };
    case SET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
      };
    case SET_CONSTANTS:
      return {
        ...state,
        absency_reasons: [
          "ZwolnienieLekarskie",
          "Delegacja",
          "Egzaminy",
          "KonferencjaMetodyczna",
          "LosowaNieobecnoscUsprawiedliwiona",
          "NieobecnoscNieusprawiedliwiona",
          "OpiekaNadDzieckiem",
          "UrlopBezplatny",
          "UrlopOkolicznosciowy",
          "ZajeciaZInnaKlasa",
          "ZajeciaOdwolaneZPrzyczynLosowych",
          "Urlop",
        ],

        absency_effects: [
          "Przeniesiona",
          "Zastepstwo",
          "UczniowiePrzychodzaPozniej",
          "UczniowieZwolnieniDoDomu",
          "BezKonsekwencji",
          "OkienkoDlaUczniow",
          "ZlaczenieGrup",
        ],
        paymentForm: ["DodatkowoPlatne", "Bezplatne", "GodzinaDoZliczenia"],
        contractType: ["Etat", "UmowaODzielo", "UmowaZlecenie"],

        weekImportOptions: ["Import", /*"ImportAsFree",*/ "NoImport"],
        eventType: [
          "Class",
          "Special",
          "Extra",
          "SpecialHoliday",
          "SpecialFullTimeAvg",
          "SpecialMonthlyAddition",
          "SpecialMonthlyContract"
        ],
        conflictTypes: [
          "Holiday",
          "NotConfirmed",
          "NotApproved",
          "EmployeeParallel",
          "AttendeeParallel",
        ],

        // substitution_forms: [
        //   { id: 1, name: "Z nauczycielem tego samego przedmiotu" },
        //   { id: 2, name: "Z nauczycielem uczącym w oddziale" },
        //   { id: 3, name: "Zastępstwo przypadkowe" },
        // ],
      };
    default:
      return state;
  }
};
