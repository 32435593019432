import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import pl from "./pl.json";

const resources = {
  en: { translation: en },
  pl: { translation: pl },
};

export const defaultLanguage = "pl";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export const lang = [
  { value: "en", name: "English" },
  { value: "pl", name: "Polski" },
];

export default i18n;
