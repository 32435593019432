import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  Flex,
  Label,
  Select,
  Text,
} from "pcln-design-system";

import moment from "moment";
import { DatePickerButton } from "components";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Event } from "services";
import { useIsMounted } from "utils";
const week = [0, 1, 2, 3, 4];

const LastWeekImport = ({ data, close, onSave = () => {}, filterData }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation("translation", { keyPrefix: "plan" });
  const weekImportOptions = useSelector(
    (state) => state.constants.weekImportOptions
  );

  const [selects, setSelects] = useState(
    new Array(5).fill(weekImportOptions[0])
  );
  const [sourceWeek, setSourceWeek] = useState(
    moment(filterData.date).subtract(7, "days")
  );

  const sourceWeekString = () => {
    const friday = sourceWeek.clone().add(4, "days");
    return `${sourceWeek.format("DD.MM")} - ${friday.format("DD.MM.yyyy")}`;
  };

  const [loading, setLoading] = useState(false);

  const commitCopy = () => {
    setLoading(true);

    const copyEventData = {
      toCopy: selects
        .map((sel, ind) => {
          if (sel === "Import") {
            return {
              source: sourceWeek.clone().add(ind, "days").format('YYYY-MM-DDTHH:mm:ss'),
              target: moment(filterData.date)
                .add(ind, "days")
                .format('YYYY-MM-DDTHH:mm:ss'),
            };
          } else {
            return null;
          }
        })
        .filter((i) => i !== null),
    };

    Event.copyEvent(copyEventData)
      .then((res) => {
        setLoading(false);
        onSave();
      })
      .catch((err) => {
        console.log("axios err=", err);
        if (isMounted()) setLoading(false);
      });
  };

  return (
    <Card
      width={1}
      height={"100%"}
      color={"background.lightest"}
      boxShadowSize={"md"}
      borderRadius={10}
    >
      <Flex flexDirection={"column"} width={1} height={"100%"} p={1}>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Tydzień źródłowy:
            </Text>
          </Box>
          <Flex
            width={1 / 2}
            justifyContent={"flex-end"}
            pr={2}
            alignItems="center"
          >
            <Text bold mr={2}>
              {sourceWeekString()}
            </Text>
            <DatePickerButton
              onSelect={(date) => {
                setSourceWeek(moment(date).isoWeekday(1).startOf("day"));
              }}
            />
          </Flex>
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          p={2}
          justifyContent={"space-around"}
          alignItems="center"
        >
          <Box width={1 / 2}>
            <Text bold fontSize={3} ml={3}>
              Dni do zaimportowania:
            </Text>
          </Box>
          <Box width={1 / 2}></Box>
        </Flex>
        <Flex
          width={1}
          flexDirection={"column"}
          p={2}
          pr={3}
          pl={4}
          justifyContent={"space-between"}
        >
          {week.map((it, ind) => (
            <Flex key={ind} my={2} alignItems={"center"}>
              <Label fontSize={1}>
                {`${t(`weekday.${it}`)} (${moment(filterData.date)
                  .add(ind, "days")
                  .format("DD.MM")})`}
              </Label>
              <StyledSelect
                py={0}
                type={"checkbox"}
                id={`checkbox-${ind}`}
                value={selects[ind]}
                onChange={(event) =>
                  setSelects(
                    selects.map((item, index) =>
                      index === ind ? event.target.value : item
                    )
                  )
                }
              >
                {weekImportOptions.map((typ) => (
                  <option value={typ} key={typ}>
                    {t(`weekImportOptions.${typ}`)}
                  </option>
                ))}
              </StyledSelect>
            </Flex>
          ))}
        </Flex>
        <Flex
          width={1}
          flexDirection={"row"}
          justifyContent="space-around"
          mt={1}
          mb={1}
        >
          <Button color={"secondary"} variation={"outline"} onClick={close}>
            Anuluj
          </Button>
          <Button color={"secondary"} onClick={commitCopy} disabled={loading}>
            <Flex
              justifyContent="center"
              alignItems="center"
              pt={loading ? 1 : 0}
            >
              {loading ? (
                <PulseLoader size={10} color={"white"} />
              ) : (
                `Zatwierdź`
              )}
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

const StyledSelect = styled(Select)`
  padding: 5px 10px;
`;

export default LastWeekImport;
