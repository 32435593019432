/**
 * Color mapping for displaying events
 *
 */

export const colors = {
  Red: "#AA0E2D",
  Yellow: "#F68013",
  Green: "#00AA00",
  Black: "#82939B",
};
