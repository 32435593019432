import React, { useEffect, useState } from "react";
import "./Users.scss";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button, Card, Flex, Text } from "pcln-design-system";

import { PulseLoader } from "react-spinners";
import { Can, Icon } from "components";

import Popup from "reactjs-popup";
import styled from "styled-components";
import { useIsMounted } from "utils";
import { useUsers } from "./useUsers";
import ErrorPopup from "pages/EditData/components/ErrorPopup";
import { User } from "services";
import NewUserPopup from "./NewUserPopup";
import SetPasswordPopup from "./SetPasswordPopup";

const Users = () => {
  const { columnDefinitions, gridConfig, editedRows } = useUsers({
    onResetPassword: (cell_data) => {
      setOpenSetPasswordPopup(cell_data);
    },
  });

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const closeErrorPopup = () => {
    setOpenErrorPopup(false);
    setErrorArray([]);
  };

  const [openNewUserPopup, setOpenNewUserPopup] = useState(false);
  const closeNewUserPopup = () => {
    setOpenNewUserPopup(false);
    setRefresh(!refresh);
  };

  const [openSetPasswordPopup, setOpenSetPasswordPopup] = useState(false);
  const closeSetPasswordPopup = () => {
    setOpenSetPasswordPopup(false);
    setRefresh(!refresh);
  };

  const isMounted = useIsMounted();

  useEffect(
    () => {
      setLoading(true);
      editedRows.set([]);
      User.get()
        .then((res) => {
          if (isMounted()) {
            setRowData(
              res.data.data.results.map((it, ind) => ({ ...it, id: ind }))
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("axios err=", err);
          if (isMounted()) setLoading(false);
        });

      return () => {};
    },
    // eslint-disable-next-line
    [refresh, isMounted]
  );

  const [triggerSave, setTriggerSave] = useState(false);
  useEffect(
    () => {
      setLoading(true);
      Promise.allSettled(
        editedRows.get.map((row) => {
          if (row.data.userId) {
            return User.put(row.data.userId, {
              emailAddress: row.data.emailAddress,
              name: row.data.userName,
            });
          } else {
            return User.post(row.data);
          }
        })
      ).then((responses) => {
        responses.forEach((res, index) => {
          if (res?.status === "rejected")
            setErrorArray([
              ...errorArray,
              {
                text: <b>{editedRows.get[index].data.name}</b>,
                rowIndex: editedRows.get[index].rowIndex,
                resData: res?.reason?.response?.data,
              },
            ]);
        });

        if (responses.filter((r) => r.status === "rejected").length)
          setOpenErrorPopup(true);

        setLoading(false);
        setRefresh(!refresh);
      });

      return () => {};
    },
    // eslint-disable-next-line
    [triggerSave, isMounted]
  );

  const handleSaveButton = () => {
    setTriggerSave(!triggerSave);
  };

  return (
    <>
      <SCard
        color="background.lightest"
        boxShadowSize="md"
        borderWidth={0}
        borderRadius={5}
      >
        <Flex
          width={1}
          height={"100%"}
          flexDirection="column"
          className={"container"}
        >
          <Flex
            width={1}
            p={2}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Flex ml={"auto"} mr={3} alignItems={"center"}>
              <Can
                perform={"user:add"}
                yes={() => (
                  <Button
                    variation={"outline"}
                    onClick={() => setOpenNewUserPopup(true)}
                  >
                    <Flex flexDirection="row">
                      <Icon
                        name="Plus"
                        width="20px"
                        height="20px"
                        color={"primary.base"}
                      />
                      <Text ml={2}>Utwórz użytkownika</Text>
                    </Flex>
                  </Button>
                )}
              />
              <Button
                ml={2}
                variation={"fill"}
                color="secondary"
                disabled={!editedRows.get.length}
                onClick={() => {
                  handleSaveButton();
                }}
              >
                <Flex flexDirection="row">
                  {loading ? (
                    <PulseLoader size={10} color={"white"} />
                  ) : (
                    <>
                      <Icon
                        name="Diskette"
                        width="20px"
                        height="20px"
                        color={
                          !editedRows.get.length
                            ? "text.light"
                            : "primary.lightest"
                        }
                      />
                      <Text ml={2}>Zapisz</Text>
                    </>
                  )}
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <div
            className="ag-custom-theme-tight-users"
            style={{ height: "100%", width: "100%" }}
          >
            {!loading ? (
              <AgGridReact rowData={rowData} {...gridConfig}>
                {columnDefinitions().map((col, index) => (
                  <AgGridColumn {...col} key={index} />
                ))}
              </AgGridReact>
            ) : (
              <Flex
                width={1}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PulseLoader color={"blue"} margin={10} />
              </Flex>
            )}
          </div>
        </Flex>
      </SCard>
      <StyledPopup
        open={openErrorPopup}
        closeOnDocumentClick
        onClose={closeErrorPopup}
      >
        {(close) => <ErrorPopup data={errorArray} close={close} />}
      </StyledPopup>
      <StyledPopup
        open={openNewUserPopup}
        closeOnDocumentClick
        onClose={closeNewUserPopup}
      >
        {(close) => <NewUserPopup close={close} />}
      </StyledPopup>
      <StyledPopup
        open={!!openSetPasswordPopup}
        closeOnDocumentClick
        onClose={closeSetPasswordPopup}
      >
        {(close) => (
          <SetPasswordPopup data={openSetPasswordPopup} close={close} />
        )}
      </StyledPopup>
    </>
  );
};

const SCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  /* min-height: calc(100vh - 100px - 40px); */
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    padding: 50px 0px;
  }
  &-content {
    width: clamp(470px, 70vw, 650px);
  }
`;

export { Users };
